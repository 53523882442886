import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-tuto-buttons',
  templateUrl: './tuto-buttons.component.html',
  styleUrls: ['./tuto-buttons.component.css', '../../../app/app.component.css', '../tuto.component.css']
})
export class TutoButtonsComponent implements OnInit {
  constructor() {
  }

  ngOnInit() {
  }
}
