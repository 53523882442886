import {Component, Input, OnInit} from '@angular/core';
import {ClinicalCaseService} from '../../../services/clinical-case.service';

export interface Resume {
  category: string;
  infos: any;
}

/**
 * Categories of the summary
 */
const RESUME_DATA: Resume[] = [
  {category: 'Interrogatoire', infos: ''},
  {category: 'Examens', infos: ''},
  {category: 'Diagnostic', infos: ''},
  {category: 'Traitement', infos: ''},
];

@Component({
  selector: 'app-resume',
  templateUrl: './summary.component.html',
  styleUrls: ['./summary.component.css']
})

/**
 * This component represents the summary of the current clinical case.
 * All informations about it can be found here as a summary for user.
 */
export class SummaryComponent implements OnInit {

  displayedColumns = ['categorie', 'infos'];

  /**
   * Categories of the summary
   */
  @Input() dataSource = RESUME_DATA;

  constructor(private clinicalCaseService: ClinicalCaseService) {
  }

  ngOnInit(): void {
    this.clinicalCaseService.getClinicalCase();
  }

  /**
   * Get clinical case from service
   */
  getClinicalCase(): any {
    return this.clinicalCaseService;
  }

}
