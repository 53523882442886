import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-tuto-menu-example',
  templateUrl: './tuto-menu-example.component.html',
  styleUrls: ['./tuto-menu-example.component.css', '../../../app/app.component.css']
})
export class TutoMenuExampleComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {
  }

}
