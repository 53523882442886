/**
 * This class represents a list diagnostics
 */
export class Diagnostic {

  /**
   * List of diagnostics
   */
  diagnosticList: Array<string>;

  constructor(diagnosticList: Array<string>) {
    this.diagnosticList = diagnosticList;
  }

  /**
   * Add a diagnostic to the list
   * @param newDiagnostic diagnostic to add
   */
  addDiagnostic(newDiagnostic: string): void {
    this.diagnosticList.push(newDiagnostic);
  }

  /**
   * Retrieve a diagnostic from the list
   * @param diagnostic diagnostic to retrieve
   */
  removeDiagnostic(diagnostic: string): void {
    this.diagnosticList = this.diagnosticList.filter(obj => obj !== diagnostic);
  }
}
