import {Component, OnInit} from '@angular/core';
import {ClinicalCaseService} from '../../../services/clinical-case.service';

@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.css']
})
/**
 * This is the component for the general information of the clinical case such as the name of the case,
 * the difficulty, etc.
 */
export class GeneralInfoComponent implements OnInit {

  idCreator = '';
  difficulty = '';
  fields = '';
  name = '';
  careContext = '';

  constructor(private clinicalCaseService: ClinicalCaseService) {
  }

  ngOnInit(): void {
    this.idCreator = this.clinicalCaseService.getClinicalCase().idCreator;
    this.difficulty = this.clinicalCaseService.getClinicalCase().difficulty;
    this.fields = this.clinicalCaseService.getClinicalCase().fields;
    this.name = this.clinicalCaseService.getClinicalCase().name;
    this.careContext = this.clinicalCaseService.getClinicalCase().careContext;
  }

  /**
   * Save clinical case model
   */
  saveData(): void {
    this.clinicalCaseService.saveGeneralInfo(this.name, this.fields, this.idCreator, this.difficulty, this.careContext);
  }
}
