import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListService {
  private list = new BehaviorSubject<string[]>([]);
  private type = new BehaviorSubject<string>('a');
  cast = this.list.asObservable();
  castType = this.type.asObservable();

  constructor() {
  }

  editList(newList) {
    this.list.next(newList);
  }

  editType(newType) {
    this.type.next(newType);
  }
}
