import {Component, Input, OnInit} from '@angular/core';
import {ListService} from '../../../services/list.service';
import {ClinicalCaseService} from '../../../services/clinical-case.service';
import {PlayGameComponent} from '../../play-game/play-game.component';
import {AppComponent} from '../../app/app.component';
import {ClinicalCase} from '../../../data/model/clinicalCase';

@Component({
  selector: 'app-care-menu',
  templateUrl: './care-menu.component.html',
  styleUrls: ['./care-menu.component.css', '../../app/app.component.css']
})
export class CareMenuComponent implements OnInit {
  playGameComp: PlayGameComponent;
  appComp: AppComponent;


  @Input() clinicalCase: ClinicalCase;

  // list for ListService
  list: string[];

  constructor(private listService: ListService, private playGameComponent: PlayGameComponent,
              private appComponent: AppComponent, private clinicalCaseService: ClinicalCaseService) {
    this.playGameComp = playGameComponent;
    this.appComp = appComponent;
  }

  ngOnInit() {
    // subscribe to ListService
    this.listService.cast.subscribe(list => this.list = list);

    // Subscribe to clinical case service
    this.clinicalCase = this.clinicalCaseService.getClinicalCase();
  }

  // methods to launch different componentList and edit listComponent
  launchMedicalTreatment() {
    this.listService.editList(this.clinicalCase.careGame.medicalTreatmentList);
    this.listService.editType('Medical');
  }

  launchOrthpedicTreatment() {
    this.listService.editList(this.clinicalCase.careGame.orthopedicTreatmentList);
    this.listService.editType('Orthopedic');
  }

  launchFunctionelTreatment() {
    this.listService.editList(this.clinicalCase.careGame.functionalTreatmentList);
    this.listService.editType('Functionnel');
  }

  launchSurgicalTreatment() {
    this.listService.editList(this.clinicalCase.careGame.surgicalTreatmentList);
    this.listService.editType('Surgical');
  }

  launchOthersTreatment() {
    this.listService.editList(this.clinicalCase.careGame.otherTreatmentList);
    this.listService.editType('Others');
  }


}
