import {Component, Input, OnInit} from '@angular/core';
import {CheckUp} from '../../../data/model/checkUp';
import {FileExamenList} from '../../../data/model/file';
import {ClinicalCaseService} from '../../../services/clinical-case.service';

@Component({
  selector: 'app-examen-imagerie',
  templateUrl: './check-up-image.component.html',
  styleUrls: ['./check-up-image.component.css']
})

/**
 * This component is used to represent imaging exams ('check up image')
 */
export class CheckUpImageComponent implements OnInit {

  /**
   * Current expansion pane opened (if one is opened, otherwise step === -1)
   */
  step = -1;

  /**
   * All types of expansion pane
   */
  @Input() checkUps: CheckUp[] = [new CheckUp(1, 'Examen de radiographie', [], '', 10),
    new CheckUp(1, 'Examen de radiographie dynamique', [], '', 10),
    new CheckUp(1, 'Examen de scanner', [], '', 10),
    new CheckUp(1, 'Examen d\'arthroscanner', [], '', 10),
    new CheckUp(1, 'Examen d\'IRM', [], '', 10),
    new CheckUp(1, 'Examen de scintigraphie', [], '', 10)];

  /**
   * New categories of exam added by user
   */
  newExams: number[] = [];

  constructor(private clinicalCaseService: ClinicalCaseService) {
  }

  ngOnInit(): void {
    this.checkUps = this.clinicalCaseService.getClinicalCase().checkUpImagesList;
  }

  /**
   * Go to next expansion pane
   */
  nextStep(): void {
    this.step++;
  }

  /**
   * Go to previous expansion pane
   */
  prevStep(): void {
    this.step--;
  }

  /**
   * Set expansion pane opened
   */
  setStep(idx: number): void {
    this.step = idx;
  }

  /**
   * Add a new exam to the initial list of exam
   *
   * 25/06/2021 : Note that this exam is added to the downloaded clinical case at the end of the creation.
   * However, when playing the clinical case, the reading of exam added in addition of the initial exam list is not yet supported.
   * Addings must be done in CLinicalInfoMenuComponent to support it.
   */
  addExam(): void {
    this.checkUps.push(new CheckUp(1, 'Autre examen : ', [], '', 0));
    this.newExams.push(this.checkUps.length - 1);
    this.step = this.checkUps.length - 1;
  }

  /**
   * Remove an exam from the list
   * @param idx index of exam to remove
   */
  removeExam(idx: number): void {
    if (this.isNewExam(idx)) {
      this.checkUps.splice(idx, 1);
    }
  }

  /**
   * Tells whether the exam of the index idx is one added by user or not
   * @param idx index of the exam
   */
  isNewExam(idx: number): boolean {
    return this.newExams.includes(idx);
  }

  /**
   * Select and download a new file to add it in model
   * File is saved in an object containing File and its string representation (conversion of file in string object)
   * @param event file selected
   */
  onFileSelected(event: any): void {
    if (this.step !== -1) {
      const fileReader = new FileReader();
      for (const file of event.target.files) {
        fileReader.readAsDataURL(file);
        fileReader.onload = (evt: any) => {
          try {
            this.checkUps[this.step].addFileBlobInJSON(file, evt.target.result);
          } catch (error) {
            console.log(error);
          }
        };
      }
      this.saveData();
    }
  }

  /**
   * Save clinical case model
   */
  saveData(): void {
    this.clinicalCaseService.saveCheckUpImage(this.checkUps);
  }

}
