import {Component, OnInit} from '@angular/core';
import {NotesListService} from '../../../services/notes-list.service';
import {SelectedOptionService} from '../../../services/selected-option.service';

@Component({
  selector: 'app-notes-diagnostic',
  templateUrl: './notes-diagnostic.component.html',
  styleUrls: ['./notes-diagnostic.component.css']
})
export class NotesDiagnosticComponent implements OnInit {
  constructor(private notesService: NotesListService, private diagnosticService: SelectedOptionService) {
  }

  notes: string;
  SelectedOptions: string[];

  ngOnInit() {
    // subscribe to noteService
    this.notesService.cast.subscribe(notes => this.notes = notes);
    this.diagnosticService.cast.subscribe(SelectedOptions => this.SelectedOptions = SelectedOptions);
  }


}
