import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppRoutingModule} from './app-routing.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// components
import {
  AppComponent,
  PopupForbiddenBackForwardDialogComponent,
  PopupHomeFromTutorialDialogComponent,
  PopupLogOutDialogComponent,
  PopupLogOutFromTutorialDialogComponent,
  PopupReturnHomeDialogComponent
} from '../components/app/app.component';
import {LoginComponent} from '../components/login/login.component';
import {HomeComponent} from '../components/home/home.component';
import {
  PlayGameComponent,
  PopupDiagnostic,
  PopupEmergencyDialog,
  PopupEndGameDialog,
  PopupIntroductionGameDialog
} from '../components/play-game/play-game.component';
import {AddGameComponent} from '../components/add-game/add-game.component';
import {DiagnosticComponent} from '../components/add-game/diagnostic/diagnostic.component';
import {CheckUpBiologyComponent} from '../components/add-game/check-up-biology/check-up-biology.component';
import {CheckUpImageComponent} from '../components/add-game/check-up-image/check-up-image.component';
import {CheckUpObservationComponent} from '../components/add-game/check-up-observation/check-up-observation.component';
import {GeneralInfoComponent} from '../components/add-game/general-info/general-info.component';
import {PatientInfoComponent} from '../components/add-game/patient-info/patient-info.component';
import {ClinicalTestsComponent} from '../components/add-game/clinical-tests/clinical-tests.component';
import {SummaryComponent} from '../components/add-game/summary/summary.component';
import {ClinicalTestDetailComponent} from '../components/add-game/clinical-tests/clinical-test-detail/clinical-test-detail.component';
import {CareComponent} from '../components/add-game/care/care.component';
import {DisplayComponent} from '../components/toolbox/display/display.component';
import {TemplateListComponent} from '../components/toolbox/template-list/template-list.component';
import {TutoComponent} from '../components/toolbox/tuto/tuto.component';
import {EvaluationComponent} from '../components/toolbox/evaluation/evaluation.component';
import {TemplateTextComponent} from '../components/toolbox/template-text/template-text.component';
import {NotesComponent} from '../components/toolbox/notes/notes.component';
import {NotesDiagnosticComponent} from '../components/toolbox/notes-diagnostic/notes-diagnostic.component';
import {NotesTreatmentComponent} from '../components/toolbox/notes-treatment/notes-treatment.component';
import {ClinicalInfoMenuComponent} from '../components/toolbox/clinical-info-menu/clinical-info-menu.component';
import {CareMenuComponent} from '../components/toolbox/care-menu/care-menu.component';
import {
  ClinicalCasesDataTableComponent,
  PopupRulesGameDialogComponent
} from '../components/toolbox/clinical-cases-data-table/clinical-cases-data-table.component';
import {TutoButtonsComponent} from '../components/toolbox/tuto/tuto-buttons/tuto-buttons.component';
import {TutoMenuExampleComponent} from '../components/toolbox/tuto/tuto-menu-example/tuto-menu-example.component';
import {TemplateButtonComponent} from '../components/toolbox/template-button/template-button.component';


// services
import {ListService} from '../services/list.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';


// PHP
import {HttpClientModule} from '@angular/common/http';

// Material modules
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import {MatTableModule} from '@angular/material/table';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatCardModule} from '@angular/material/card';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';

// for images  videos an interfaces
import {MaterialModules} from './app-material-modules';
import {NgxGalleryModule} from 'ngx-gallery';
import {MatVideoModule} from 'mat-video';
import {GalleryModule} from '@ngx-gallery/core';
import {LightboxModule} from '@ngx-gallery/lightbox';
import {GallerizeModule} from '@ngx-gallery/gallerize';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {AddGameModule} from './add-game-module/add-game.module';
import {ClinicalCaseServiceModule} from './clinical-case-service/clinical-case-service.module';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeComponent,
    PlayGameComponent,
    ClinicalCasesDataTableComponent,
    PopupIntroductionGameDialog,
    PopupRulesGameDialogComponent,
    PopupEndGameDialog,
    PopupEmergencyDialog,
    PopupLogOutDialogComponent,
    PopupLogOutFromTutorialDialogComponent,
    PopupReturnHomeDialogComponent,
    PopupHomeFromTutorialDialogComponent,
    PopupForbiddenBackForwardDialogComponent,
    PopupDiagnostic,
    DisplayComponent,
    TemplateListComponent,
    TutoComponent,
    EvaluationComponent,
    TemplateTextComponent,
    NotesComponent,
    NotesDiagnosticComponent,
    NotesTreatmentComponent,
    ClinicalInfoMenuComponent,
    CareMenuComponent,
    TutoButtonsComponent,
    TutoMenuExampleComponent,
    TemplateButtonComponent,

  ],
  imports: [
    AddGameModule,
    ClinicalCaseServiceModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModules,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgxGalleryModule,
    MatVideoModule,
    GalleryModule,
    LightboxModule,
    GallerizeModule,
    MatCheckboxModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatTabsModule,
    MatGridListModule,
    MatMenuModule,
    MatBadgeModule,
    MatTableModule,
    MatIconModule,
    MatListModule,
    MatSelectModule,
    MatRadioModule,
    MatExpansionModule,
    MatFormFieldModule,
  ],
  providers: [ListService, FileOpener],
  bootstrap: [AppComponent],
  entryComponents: [PopupIntroductionGameDialog, PopupRulesGameDialogComponent, PopupEndGameDialog,
    PopupEmergencyDialog, PopupDiagnostic, PopupLogOutDialogComponent, PopupLogOutFromTutorialDialogComponent,
    PopupReturnHomeDialogComponent, PopupHomeFromTutorialDialogComponent, PopupForbiddenBackForwardDialogComponent],
})
export class AppModule {
}
