import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ListTreatmentService {

  private treatement = new BehaviorSubject<Array<string>>([]);
  private treatementMedical = new BehaviorSubject<Array<string>>([]);
  private treatementOrthopedic = new BehaviorSubject<Array<string>>([]);
  private treatementFct = new BehaviorSubject<Array<string>>([]);
  private treatementSurgical = new BehaviorSubject<Array<string>>([]);
  private treatementOthers = new BehaviorSubject<Array<string>>([]);

  cast = this.treatement.asObservable();
  castMedical = this.treatementMedical.asObservable();
  castOrtho = this.treatementOrthopedic.asObservable();
  castfct = this.treatementFct.asObservable();
  castSurgical = this.treatementSurgical.asObservable();
  castOthers = this.treatementOthers.asObservable();

  constructor() {
  }

  editTreatement(newTreatment) {
    this.treatement.next(newTreatment);
  }

  editTreatementMedical(newTreatment) {
    this.treatementMedical.next(newTreatment);
  }

  editTreatementOrthopedic(newTreatment) {
    this.treatementOrthopedic.next(newTreatment);
  }

  editTreatementFct(newTreatment) {
    this.treatementFct.next(newTreatment);
  }

  editTreatementSurgical(newTreatment) {
    this.treatementSurgical.next(newTreatment);
  }

  editTreatementOthers(newTreatment) {
    this.treatementOthers.next(newTreatment);
  }

}
