import {Component, Inject, Input, OnInit,} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material';
import {AppComponent} from '../app/app.component';
import {Router} from '@angular/router';
import {ListService} from '../../services/list.service';
import {DisplayService} from '../../services/display.service';
import {TextService} from '../../services/text.service';
import {SelectedOptionService} from '../../services/selected-option.service';
import {DiagnosticService} from '../../services/diagnostic.service';
import {NotesListService} from '../../services/notes-list.service';
import {ListTreatmentService} from '../../services/list-treatment.service';
import {Timer} from '../../data/model/timer';
import {TimerService} from '../../services/timer.service';
import {LogService} from '../../services/log.service';
import {CLINICALCASE} from '../../data/mock/CLINICALCASE';
import {ClinicalCase} from '../../data/model/clinicalCase';
import {Log} from '../../data/model/log';
import {PlatformLocation} from '@angular/common';
import {ClinicalCaseService} from '../../services/clinical-case.service';



export interface DialogData {
  message: string;
}

@Component({
  selector: 'app-play-game',
  templateUrl: './play-game.component.html',
  styleUrls: ['./play-game.component.css', '../app/app.component.css'],
})
export class PlayGameComponent implements OnInit {

  appComp: AppComponent;
  isComponentVisibleDsiplay: boolean;
  isComponentVisibleList: boolean;
  isComponentVisibleText: boolean;
  isComponentVisibleNote: boolean;
  isComponentVisibleButtons: boolean;

  isLinear = false;

  @Input() clinicalCase: ClinicalCase;

  noteCC: any;
  // list for ListService
  list: string[];

// string for url for displayService
  url: string;
  url2: string;
  result: string;

// string for notes
  notes: string;

  // string for TextService
  text: string;

  // string for OptionSelectedService
  SelectedOptions: string[];

  // string for DiagnosticService
  diagnostic = [];

  // array for listreatement
  treatmentList: string[];

  // objects for timer
  timer: Timer = new Timer(0, 0);
  minutes: number;
  secondes: number;
  log: Log;
  listLog: Log[] = [this.log];

  constructor(
    private dialog: MatDialog,
    public appComponent: AppComponent,
    private formBuilder: FormBuilder,
    private router: Router,
    // service creation
    private listService: ListService,
    private displayService: DisplayService,
    private notesService: NotesListService,
    private textService: TextService,
    private selectedOptionService: SelectedOptionService,
    private diagnosticService: DiagnosticService,
    private treatmentService: ListTreatmentService,
    private timerService: TimerService,
    private logService: LogService,
    location: PlatformLocation,
    private clinicalCaseService: ClinicalCaseService
  ) {
    this.appComp = appComponent;
    this.appComponent.ShowButtonHome();
    this.appComponent.HideButtonHomefromTuto();
    this.appComponent.HideButtonLogOut();
    this.appComponent.HideButtonLogOutFromTuto();
    this.appComponent.topTitle = ' Jeu';

    // Listcomponent and displaycomponent false when initializing
    this.isComponentVisibleDsiplay = false;
    this.isComponentVisibleList = false;
    this.isComponentVisibleButtons = false;

    this.isComponentVisibleNote = true;

    history.pushState(null, null, location.href);
    window.onpopstate = function () {
      history.go(1);
    };

    window.addEventListener('beforeunload', function (e) {
      const confirmationMessage = '\o/';
      e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
      return confirmationMessage;              // Gecko, WebKit, Chrome <34
    });
  }


// Interface----------------------------------------------------------------------------
  // mat-horizontal-stepper

  // mat-step
  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  fourthFormGroup: FormGroup;


  ngOnInit() {
    // Subscribe to clinical case service
    this.clinicalCase = this.clinicalCaseService.getClinicalCase();

    // information nécessaire pour le stepper
    this.firstFormGroup = this.formBuilder.group({
      firstCtrl: ['', Validators.required]
    });
    this.secondFormGroup = this.formBuilder.group({
      secondCtrl: ['', Validators.required]
    });
    this.thirdFormGroup = this.formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });
    this.fourthFormGroup = this.formBuilder.group({
      thirdCtrl: ['', Validators.required]
    });

    //  pop-up open when the page is open
    this.dialog.open(PopupIntroductionGameDialog).disableClose = true;

    // subscribe to ListService
    this.listService.cast.subscribe(list => this.list = list);

    // subscribe to DisplayService
    this.displayService.castUrl.subscribe(url => this.url = url);
    this.displayService.castResult.subscribe(result => this.result = result);
    this.displayService.castUrl2.subscribe(url2 => this.url2 = url2);

    // subscribe to noteService
    this.notesService.cast.subscribe(notes => this.notes = notes);

    // subscribe to textService
    this.textService.cast.subscribe(text => this.text = text);

    // subscribe to optionService
    this.selectedOptionService.cast.subscribe(SelectedOptions => this.SelectedOptions = SelectedOptions);

    // subscribe to diagnosticService
    this.diagnosticService.cast.subscribe(diagnostic => this.diagnostic = diagnostic);

    // subscribe to treatment list service
    this.treatmentService.cast.subscribe(treatmentList => this.treatmentList = treatmentList);

    // timer
    this.timer.start();
    this.timerService.castMinutes.subscribe(minutes => this.minutes = minutes);
    this.timerService.castSecondes.subscribe(minutes => this.secondes = minutes);
    this.timerService.editTimer(this.timer);
    // textService
    this.textService.editText(this.clinicalCase.careContext);

    // subscribe to log service
    this.logService.cast.subscribe(log => this.listLog = log);
    this.logService.castLog.subscribe(log => this.log = log);
  }

  // functions to launch pop up
  launchEndGame() {
    this.dialog.open(PopupEndGameDialog).disableClose = true;
  }

  launchPopUpDiagnostic() {
    this.dialog.open(PopupDiagnostic).disableClose = true;
  }

  launchEmergencyState() {
    this.dialog.open(PopupEmergencyDialog).disableClose = true;
  }

  launchlog() {
    localStorage.setItem('log', JSON.stringify(this.appComp.listLog));
  }


  // functions to display or not ListComponent and display component
  ShowComponentDisplayVisible() {
    this.isComponentVisibleDsiplay = true;
  }

  ShowComponentDisplayHide() {
    this.isComponentVisibleDsiplay = false;
  }

  ShowComponentListVisible() {
    this.isComponentVisibleList = true;
  }

  ShowComponentListHide() {
    this.isComponentVisibleList = false;
  }

  ShowComponentTextVisible() {
    this.isComponentVisibleText = true;
  }

  ShowComponentTextHide() {
    this.isComponentVisibleText = false;
  }

  ShowComponentNoteVisible() {
    this.isComponentVisibleNote = true;
  }

  ShowComponentNoteHide() {
    this.isComponentVisibleNote = false;
  }

  ShowComponentButtonsVisible() {
    this.isComponentVisibleButtons = true;
  }

  ShowComponentButtonsHide() {
    this.isComponentVisibleButtons = false;
  }


  launchDiagnostic() {
      this.listService.editList(this.clinicalCase.diagnosticGame.diagnosticList);
      this.listService.editType('a');
  }

  launchNotes() {
    this.notesService.editList(JSON.parse(localStorage.getItem('note')));
    console.log(this.notes);
  }

  launchDiag() {
    this.diagnosticService.editDiagnostic(this.SelectedOptions);
    this.selectedOptionService.editOption([]);
    this.treatmentService.editTreatement([]);
  }

  stopTimer() {
    this.timerService.editMinutes(this.timer.getMinutes);
    this.timerService.editSecondes(this.timer.getSecondes);
  }


}

// --------------------------------------------------------------POP-UP------------------------------------------------------------------------------
// pop-up of clinical case introduction
@Component({
  selector: 'app-play-game',
  templateUrl: './PopupIntroductionsGameDialog.html',
})


export class PopupIntroductionGameDialog {
  @Input() clinicalCase: ClinicalCase;
  textExplanations: string;
  textActions = 'Si vous ne souhaitez pas jouer, cliquez sur "Quitter le jeu".';
  texteTimer: string;
  isButtonsIntroductionVisible = true;
  isButtonsEmergencyVisible = false;
  isButtonsEndGameVisible = false;
  timer: Timer;
  minutes: number;
  secondes: number;
  diagnostic: string;
  vraiDiag = 0;
  private notes: string;
  private selectedDiag;
  private text: string;
  log: Log;
  listLog: Log[] = [this.log];

  constructor(public dialogRef: MatDialogRef<PopupIntroductionGameDialog>, private timerService: TimerService,
              private notesService: NotesListService,
              private diagnosticService: SelectedOptionService, private textService: TextService,
              private logService: LogService,
              private clinicalCaseService: ClinicalCaseService,
              @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.timerService.castTimer.subscribe(timer => this.timer = timer);
    this.timerService.castMinutes.subscribe(minutes => this.minutes = minutes);
    this.timerService.castSecondes.subscribe(secondes => this.secondes = secondes);
    // subscribe to log service
    this.logService.cast.subscribe(log => this.listLog = log);
    this.logService.castLog.subscribe(log => this.log = log);
  }

  ngOnInit(): void {
    // Subscribe to clinical case service
    this.clinicalCase = this.clinicalCaseService.getClinicalCase();
    this.textExplanations = this.clinicalCase.careContext;
  }

  changeWindowToEmergency() {
    this.textExplanations = 'Vous avez déclenché le bouton d\'urgence. Ce bouton permet de réorienter le patient vers un médecin.';
    this.textActions = 'Vous pouvez valider votre décision ou continuer à prendre en charge ce patient. ';
    this.isButtonsIntroductionVisible = false;
    this.isButtonsEmergencyVisible = true;
    this.isButtonsEndGameVisible = false;
  }

  changeWindowToEndGame() {
    if (this.clinicalCase.diagnosticSolution.diagnosticList[0] == 'Réorientation immédiate') {
      this.vraiDiag = 1;
    } else {
      this.vraiDiag = 2;

    }
    this.timerService.editMinutes(this.timer.getMinutes);
    this.timerService.editSecondes(this.timer.getSecondes);
    this.timer.stop();
    this.textExplanations = 'Fin du jeu.';
    this.textActions = 'Résultats :';
    this.texteTimer = 'Vous avez mis : ' + this.minutes + ' minutes et ' + this.secondes + ' secondes';
    this.isButtonsIntroductionVisible = false;
    this.isButtonsEmergencyVisible = false;
    this.isButtonsEndGameVisible = true;
    localStorage.setItem('cc', JSON.stringify(CLINICALCASE));
    localStorage.removeItem('note');
    this.notesService.editList('');
    this.textService.editText('');
    this.logService.editListLogExamen([]);
  }

  toggle(event) {
    this.logService.editLog({
      id: event.target.id,
      temps: new Timer(this.timer.getMinutes, this.timer.getSecondes),
    });
    this.listLog.push(this.log);
    console.log(this.listLog);
    this.logService.editListLogExamen(this.listLog);
  }
}

// pop-up of end of the game explanation
@Component({
  selector: 'app-play-game',
  templateUrl: './PopupEndGameDialog.html',
})

export class PopupEndGameDialog {
  @Input() clinicalCase: ClinicalCase;
  minutes: number;
  secondes: number;
  treatmentMedical: string[];
  treatmentOrtho: string[];
  treatmentFct: string[];
  treatmentSurgical: string[];
  treatmentOthers: string[];
  trueTreMedical = 3;
  trueTreOrtho = 3;
  trueTreFct = 3;
  trueTreSurgical = 3;
  trueTreOthers = 3;
  TOTALVRAI = 0;
  TOTALFALSE;

  constructor(
    public dialogRef: MatDialogRef<PopupDiagnostic>, private timerService: TimerService,
    private notesService: NotesListService,
    private diagnosticService: SelectedOptionService, private textService: TextService, private treatmentService: ListTreatmentService,
    private logService: LogService,
    private clinicalCaseService: ClinicalCaseService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.timerService.castMinutes.subscribe(minutes => this.minutes = minutes);
    this.timerService.castSecondes.subscribe(secondes => this.secondes = secondes);
    this.treatmentService.castMedical.subscribe(treatmentMedical => this.treatmentMedical = treatmentMedical);
    this.treatmentService.castOrtho.subscribe(treatmentOrtho => this.treatmentOrtho = treatmentOrtho);
    this.treatmentService.castfct.subscribe(treatmentfct => this.treatmentFct = treatmentfct);
    this.treatmentService.castSurgical.subscribe(treatmentSurgical => this.treatmentSurgical = treatmentSurgical);
    this.treatmentService.castOthers.subscribe(treatmentOthers => this.treatmentOthers = treatmentOthers);
    }

  ngOnInit(): void {
    // Subscribe to clinical case service
    this.clinicalCase = this.clinicalCaseService.getClinicalCase();
    if (this.clinicalCase.careSolution.medicalTreatmentList.toString() == this.treatmentMedical.toString()) {
      this.trueTreMedical = 1;
      this.TOTALVRAI++;
    } else {
      this.trueTreMedical = 0;
    }
    if (this.clinicalCase.careSolution.otherTreatmentList.toString() == this.treatmentOthers.toString()) {
      this.trueTreOthers = 1;
      this.TOTALVRAI++;
    } else {
      this.trueTreMedical = 0;
    }
    if (this.clinicalCase.careSolution.surgicalTreatmentList.toString() == this.treatmentSurgical.toString()) {
      this.trueTreSurgical = 1;
      this.TOTALVRAI++;
    } else {
      this.trueTreSurgical = 0;
    }
    if (this.clinicalCase.careSolution.functionalTreatmentList.toString() == this.treatmentFct.toString()) {
      this.trueTreFct = 1;
      this.TOTALVRAI++;
    } else {
      this.trueTreFct = 0;
    }
    if (this.clinicalCase.careSolution.orthopedicTreatmentList.toString() == this.treatmentOrtho.toString()) {
      this.trueTreOrtho = 1;
      this.TOTALVRAI++;
    } else {
      this.trueTreOrtho = 0;
    }
  }

  editList() {
    this.treatmentService.editTreatementOthers([]);
    this.treatmentService.editTreatementMedical([]);
    this.treatmentService.editTreatementOrthopedic([]);
    this.treatmentService.editTreatementSurgical([]);
    this.treatmentService.editTreatementFct([]);
  }
}

// pop-up of diagnostic and choice
@Component({
  selector: 'app-play-game',
  templateUrl: './PopupDiagnostic.html',
})

export class PopupDiagnostic implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<PopupDiagnostic>, private diagnosticService: DiagnosticService,
    private timerService: TimerService, private logService: LogService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData,
    private notesService: NotesListService,
    private textService: TextService,
    private clinicalCaseService: ClinicalCaseService
  ) {
    this.diagnosticService.cast.subscribe(diagnostic => this.diagnostic = diagnostic);
    this.timerService.castTimer.subscribe(timer => this.timer = timer);
    this.timerService.castMinutes.subscribe(minutes => this.minutes = minutes);
    this.timerService.castSecondes.subscribe(secondes => this.secondes = secondes);
    // subscribe to log service
    this.logService.cast.subscribe(log => this.listLog = log);
    this.logService.castLog.subscribe(log => this.log = log);

  }

  @Input() clinicalCase: ClinicalCase;
  texteExplainations = 'Vous avez choisi ce diagnostic : ';
  texteActions = 'Les experts donnaient comme diagnostic : ';
  texteAnswer = [];
  texteTimer: string;
  textTrue: string;
  textFalse: string;
  diagnostic: string[];
  vraiDiag = 0;
  timer: Timer;
  minutes: number;
  secondes: number;
  log: Log;
  listLog: Log[] = [this.log];
  private notes: string;
  private selectedDiag;
  private text: string;

  isButtonsDiagnosticVisible = true;
  isButtonsEmergencyVisible = false;
  isButtonsEndGameVisible = false;

  ngOnInit(): void {
    // Subscribe to clinical case service
    this.clinicalCase = this.clinicalCaseService.getClinicalCase();
    this.vraiDiag = 2;
    if (this.diagnostic.length === this.clinicalCase.diagnosticSolution.diagnosticList.length) {
      const diag1 = this.diagnostic.sort();
      const diag2 = this.clinicalCase.diagnosticSolution.diagnosticList.sort();
      let breaking = false;
      let i = 0;
      while (!breaking && i < diag1.length) {
        if (diag1[i] !== diag2[i]) {
          breaking = true;
        }
        i++;
      }
      if (!breaking && i === diag1.length) {
        this.vraiDiag = 1;
      }
    }
    this.texteAnswer = this.clinicalCase.diagnosticSolution.diagnosticList;
  }


  changeWindowToEmergency() {

    this.texteExplainations = 'Vous avez déclenché le bouton d\'urgence. Ce bouton permet de réorienter le patient vers un médecin.';
    this.texteActions = 'Vous pouvez valider votre décision ou continuer à prendre en charge ce patient.';
    this.textTrue = 'Bravo vous avez trouvé la bonne\n réponse il fallait bien déclencher le bouton d\'urgence !';
    this.textFalse = 'Vous avez déclenché le bouton d\'urgence. Vous aviez également la possibilité\n' +
      '        de prendre en charge ce patient. La bonne réponse aurait été :';
    this.isButtonsDiagnosticVisible = false;
    this.isButtonsEmergencyVisible = true;
    this.diagnostic = [];
    this.texteAnswer = [];

  }

  changeWindowToEndGame() {
    if (this.clinicalCase.diagnosticSolution.diagnosticList.toString() == 'Réorientation immédiate') {
      this.vraiDiag = 3;
    } else {
      this.vraiDiag = 4;
    }
    this.timerService.editMinutes(this.timer.getMinutes);
    this.timerService.editSecondes(this.timer.getSecondes);
    this.timer.stop();
    this.texteAnswer = [];
    this.diagnostic = [];
    this.texteExplainations = 'Fin du jeu.';
    this.texteActions = 'Résultats :';
    this.texteTimer = 'Vous avez mis : ' + this.minutes + ' minutes et ' + this.secondes + ' secondes';
    this.isButtonsEndGameVisible = true;
    this.isButtonsDiagnosticVisible = false;
    this.isButtonsEmergencyVisible = false;
    localStorage.setItem('cc', JSON.stringify(CLINICALCASE));
    localStorage.removeItem('note');
    this.notesService.editList('');
    this.textService.editText('');
    this.logService.editListLogExamen([]);
  }

  changeWindowToEndGameResult() {
    this.timerService.editMinutes(this.timer.getMinutes);
    this.timerService.editSecondes(this.timer.getSecondes);
    this.timer.stop();
    this.texteAnswer = [];
    this.diagnostic = [];
    this.texteExplainations = 'Fin du jeu.';
    this.texteActions = 'Résultats :';
    this.texteTimer = 'Vous avez mis : ' + this.minutes + ' minutes et ' + this.secondes + ' secondes';
    this.isButtonsEndGameVisible = true;
    this.isButtonsDiagnosticVisible = false;
    this.isButtonsEmergencyVisible = false;
    localStorage.setItem('cc', JSON.stringify(CLINICALCASE));
    this.clinicalCaseService.initClinicalCase();
    localStorage.removeItem('note');
    this.notesService.editList('');
    this.textService.editText('');
    this.logService.editListLogExamen([]);
  }


  toggle(event) {
    this.logService.editLog({
      id: event.target.id,
      temps: new Timer(this.timer.getMinutes, this.timer.getSecondes),
    });
    this.listLog.push(this.log);
    console.log(this.listLog);
    this.logService.editListLogExamen(this.listLog);
  }
}

// pop-up of the emergency state
@Component({
  selector: 'app-root',
  templateUrl: './PopupEmergencyDialog.html',
})
export class PopupEmergencyDialog implements OnInit {
  texteExplainations = ' Vous avez déclenché le bouton d\'urgence. Ce bouton permet de réorienter le patient vers un médecin.';
  texteActions = ' Vous pouvez valider votre décision ou continuer à prendre en charge ce patient.';
  texteTimer: string;
  isButtonsThirdPageVisible = false;
  isButtonOneVisible = true;
  endGame = false;
  timer: Timer;
  minutes: number;
  secondes: number;
  private notes: string;
  private selectedDiag;
  private text: string;
  @Input() clinicalCase: ClinicalCase;
  vraiDiag = 0;
  log: Log;
  listLog: Log[] = [this.log];

  constructor(
    public dialogRef: MatDialogRef<PopupEmergencyDialog>, private timerService: TimerService,
    private notesService: NotesListService,
    private textService: TextService,
    private logService: LogService,
    private clinicalCaseService: ClinicalCaseService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this.timerService.castTimer.subscribe(timer => this.timer = timer);
    this.timerService.castMinutes.subscribe(minutes => this.minutes = minutes);
    this.timerService.castSecondes.subscribe(secondes => this.secondes = secondes);
    // subscribe to log service
    this.logService.cast.subscribe(log => this.listLog = log);
    this.logService.castLog.subscribe(log => this.log = log);
  }

  ngOnInit(): void {
    // Subscribe to clinical case service
    this.clinicalCase = this.clinicalCaseService.getClinicalCase();
  }

  changeWindow() {
    if (this.clinicalCase.diagnosticSolution.diagnosticList.toString() === 'Réorientation immédiate') {
      this.vraiDiag = 1;
    } else {
      this.vraiDiag = 2;

    }
    this.timerService.editMinutes(this.timer.getMinutes);
    this.timerService.editSecondes(this.timer.getSecondes);
    this.timer.stop();
    this.texteExplainations = 'Fin du jeu.';
    this.texteActions = 'Résultats :';
    this.texteTimer = 'Vous avez mis : ' + this.minutes + ' minutes et ' + this.secondes + ' secondes';
    this.isButtonsThirdPageVisible = true;
    this.isButtonOneVisible = false;
    this.endGame = true;
    localStorage.setItem('cc', JSON.stringify(CLINICALCASE));
    this.clinicalCaseService.initClinicalCase();
    localStorage.removeItem('note');
    this.notesService.editList('');
    this.textService.editText('');
    this.logService.editListLogExamen([]);
  }

  toggle(event) {
    this.logService.editLog({
      id: event.target.id,
      temps: new Timer(this.timer.getMinutes, this.timer.getSecondes),
    });
    this.listLog.push(this.log);
    console.log(this.listLog);
    this.logService.editListLogExamen(this.listLog);
  }

}


