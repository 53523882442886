import {Component, OnInit} from '@angular/core';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from 'ngx-gallery';
import {DisplayService} from '../../../services/display.service';
import {NgxGalleryService} from '../../../services/ngx-gallery.service';


@Component({
  selector: 'app-display',
  templateUrl: './display.component.html',
  styleUrls: ['./display.component.css']
})
export class DisplayComponent implements OnInit {

  galleryOptions: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[];

  url: string;
  result: string;
  resultOther: string;
  galleryNull = {
    small: '',
    medium: '',
    big: '',
  };

  constructor(private displayService: DisplayService,
              private rjxsGallery: NgxGalleryService,
  ) {
  }

  ngOnInit() {


    this.displayService.castUrl.subscribe(url => this.url = url);
    this.displayService.castResult.subscribe(result => this.result = result);
    this.rjxsGallery.cast.subscribe(galleryImages => this.galleryImages = galleryImages);


    this.galleryOptions = [
      {
        width: '100%',
        height: '400px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        previewZoom: true,
        previewRotate: true
      },
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      {
        breakpoint: 400,
        preview: false
      }
    ];

  }

}

/*
galleryId = 'mixedExample';

constructor(private gallery: Gallery, private displayService: DisplayService,
           private rjxsGallery:NgxGalleryService, ) { }
 url: string;
 url2: string;
 result: string;
 galleryRef: GalleryRef;

 ngOnInit() {
 this.displayService.castUrl.subscribe(url => this.url = url);
 this.displayService.castUrl2.subscribe(url2 => this.url2 = url2);
 this.displayService.castResult.subscribe(result => this.result = result);
 this.rjxsGallery.cast.subscribe(galleryImages => this.galleryRef = galleryImages);





  this.galleryRef = this.gallery.ref(this.galleryId);


 this.galleryRef.addImage({
   src: '../../../assets/img/radio-1.jpg',
   thumb: './../../assets/img/radio-1.jpg',

 });

 this.galleryRef.addVideo({
   src: './../../assets/img/extensibilite.MP4',
   thumb: './../../assets/img/extensibilite.MP4',
 });

}}*/

