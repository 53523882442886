import {ClinicalCase} from '../model/clinicalCase';
import {CheckUp} from '../model/checkUp';
import {Diagnostic} from '../model/diagnostic';
import {Care} from '../model/care';
import {Patient} from '../model/patient';
import {Timer} from '../model/timer';
import {ClinicalTest} from '../model/clinicalTest';
import {FileExamenList} from '../model/file';

export let CLINICALCASE: ClinicalCase[] = [
  {
    idCC: 0,
    idCreator: 'Guillaume',
    name: 'Cheville',
    difficulty: 'Facile',
    fields: 'Musculo squelettique',
    logCorrection: 'A partir des observations visuelles, une demande de radiographie était nécessaire',
    dysfunctionDate: new Date(' Feb 08 2020 '),
    careDate: new Date(' Feb 08 2020 '),
    careContext: 'Un patient âgé de 24 ans vient d\'arriver aux urgences après un traumatisme de la cheville au cours de la pratique de football',

    // liste des imageries
    checkUpImagesList: [new CheckUp(1, 'Demande de radiographie', [new FileExamenList('../../../assets/img/radio-1.jpg'), new FileExamenList('../../../assets/img/radio-2.jpg')], 'Absence de solution de continuité au niveau osseux. Absence d\'arrachement osseux et d\'incongruence articulaire',
      10),
        new CheckUp(0, 'Demande de radiographie dynamique', [], '', 10),
        new CheckUp(0, 'Demande de scanner', [], '', 10),
        new CheckUp(0, 'Demande d\'arthroscanner', [], '', 10),
        new CheckUp(0, 'Demande d\'IRM', [], '', 10),
        new CheckUp(0, 'Demande de scintigraphie', [], '', 10)],
    // liste des examens Biologiques

    checkUpBiologyList: [new CheckUp(1, 'Biologie', [],
      '',
      10)],

    // listes des observations
    checkUpObservationList: [new CheckUp(1, 'Visuelles', [new FileExamenList('../../../assets/img/oedeme.jpg')], 'Impotence totale à l\'appui immédiat et à la marche, le patient n\'arrive pas à faire plus de quatre pas',
      10),
      new CheckUp(1, 'Palpatoire', [],
        'Douleur vive exprimée par le patient (EN = 9/10 à la palpation de l\'os naviculaire))',
        10)],

    // liste des tests cliniques
    checkUpClinicalTestList: [
      new ClinicalTest(1, 'Cheville', [], ['Test de Tiroir antérieur en position neutre non réalisable'], ['Test de Tiroir antérieur en position neutre'], 10),
      new ClinicalTest(1, 'Cheville', [], ['Test de Tiroir antérieur en flexion plantaire non réalisable'], ['Test de Tiroir antérieur en flexion plantaire'], 10),
      new ClinicalTest(1, 'Cheville', [], ['Test de Tiroir antérieur en flexion dorsale non réalisable'], ['Test de Tiroir antérieur en flexion dorsale'], 10),
      new ClinicalTest(1, 'Cheville', [], ['Test varus / inversion forcé non réalisable'], ['Test varus / inversion forcé'], 10),
      new ClinicalTest(1, 'Cheville', [], ['Test de Hopkinson (Squeeze test) non réalisable'], ['Test de Hopkinson (Squeeze test)'], 10),
      new ClinicalTest(1, 'Cheville', [], ['Test de compression en dorsi-flexion non réalisable'], ['Test de compression en dorsi-flexion'], 10),
      new ClinicalTest(1, 'Cheville', [], ['Test de Kleiger (en rotation externe) non réalisable'], ['Test de Kleiger (en rotation externe)'], 10),
      new ClinicalTest(1, 'Cheville', [], ['Test valgus /eversion forcée non réalisable'], ['Test valgus /eversion forcée'], 10), ],


    // diagnostic affichées lors du jeu
    diagnosticGame: new Diagnostic(['Entorse', 'Atteinte du ligament talo fibulaire-postérieur', 'Fracture', 'Atteinte du ligament calcanéo-fibulaire', 'Luxation', 'Atteinte du ligament collatéral médial profond', 'Atteinte de la syndesmose', 'Atteinte du ligament talo fibulaire-antérieur', 'Atteinte du ligament collatéral médial superficiel', ' Je ne sais pas']),
    // solution diagnostic (attention les solution doivent être dans se trouver dans le même ordre que dans les propositions)
    diagnosticSolution: new Diagnostic(['Entorse']),


    // traitements proposées lors du jeu
    careGame: new Care([],
      ['Anti-inflammatoire', 'Anti-coagulants', 'Anti-douleurs', 'Je ne sais pas'],
      ['Glace (3 fois/jour)', 'Compression', 'Mobilisation passive',
        'Renforcement des fibulaires infra douloureux', 'Repos total', 'Repos avec élévation de la cheville', 'Mobilisation active en infra douloureux',
        'Je ne sais pas']
      , ['Ligamentoplastie LTFA', 'Ligamentoplastie LTFP', 'Ligamentoplastie LTFA + LTFP', 'Je ne sais pas'],
      []),
    // solution aux traitements
    careSolution: new Care(
      [],
      [],
      ['Glace (3 fois/jour)', 'Compression', 'Mobilisation passive', 'Renforcement des fibulaires infra douloureux',
        'Repos avec élévation de la cheville', 'Mobilisation active en infra douloureux'],
      [],
      []),
    maxGameTime: new Timer(0, 0),
    ListInfoTrauma:
      ['J\'ai voulu faire une tête au foot et en retombant, ' +
      'j\ai senti ma cheville partir, ' +
      'j\'ai entendu un crac et je n\'ai pas pu me relever après'],

    // informations concernant le patient
    patient: new Patient(
      'Je m\'appelle Bob Sleigh et j\'ai 24 ans.',
      'Je suis étudiant et je joue au football depuis 17 ans. J\'aime faire de la randonnée avec mes amis le week-end.',
      'Je vis en appartement au 3ème étage avec ascenseur',
      'Je suis en coloc',
      'J\'ai eu une opération de l\'appendicite. ' +
      'J\'ai eu une rupture du tendon d\'achille gauche en 2016,' +
      ' J\'ai eu une rupture du LCA droit en 2018 et je fais facilement des petites entorses',
      'Si je devais donner une douleur de 0 à 10 je donnerai 5/10 au moment de la chute et maintenant 8/10')

  },

];


