import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CareComponent} from '../../components/add-game/care/care.component';
import {CheckUpBiologyComponent} from '../../components/add-game/check-up-biology/check-up-biology.component';
import {CheckUpImageComponent} from '../../components/add-game/check-up-image/check-up-image.component';
import {CheckUpObservationComponent} from '../../components/add-game/check-up-observation/check-up-observation.component';
import {ClinicalTestsComponent} from '../../components/add-game/clinical-tests/clinical-tests.component';
import {ClinicalTestDetailComponent} from '../../components/add-game/clinical-tests/clinical-test-detail/clinical-test-detail.component';
import {DiagnosticComponent} from '../../components/add-game/diagnostic/diagnostic.component';
import {GeneralInfoComponent} from '../../components/add-game/general-info/general-info.component';
import {PatientInfoComponent} from '../../components/add-game/patient-info/patient-info.component';
import {SummaryComponent} from '../../components/add-game/summary/summary.component';
import {AddGameComponent} from '../../components/add-game/add-game.component';
import {ClinicalCaseServiceModule} from '../clinical-case-service/clinical-case-service.module';
import {MaterialModules} from '../app-material-modules';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {NgxGalleryModule} from 'ngx-gallery';
import {MatVideoModule} from 'mat-video';
import {GalleryModule} from '@ngx-gallery/core';
import {LightboxModule} from '@ngx-gallery/lightbox';
import {GallerizeModule} from '@ngx-gallery/gallerize';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatInputModule} from '@angular/material/input';
import {MatCardModule} from '@angular/material/card';
import {MatButtonModule} from '@angular/material/button';
import {MatTabsModule} from '@angular/material/tabs';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import {MatTableModule} from '@angular/material/table';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSelectModule} from '@angular/material/select';
import {MatRadioModule} from '@angular/material/radio';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';

@NgModule({
  declarations: [CareComponent, CheckUpBiologyComponent, CheckUpImageComponent, CheckUpObservationComponent,
    ClinicalTestsComponent, ClinicalTestDetailComponent, DiagnosticComponent, GeneralInfoComponent, PatientInfoComponent,
  SummaryComponent, AddGameComponent],
  imports: [CommonModule,
    ClinicalCaseServiceModule,
    MaterialModules,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    NgxGalleryModule,
    MatVideoModule,
    GalleryModule,
    LightboxModule,
    GallerizeModule,
    MatCheckboxModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatTabsModule,
    MatGridListModule,
    MatMenuModule,
    MatBadgeModule,
    MatTableModule,
    MatIconModule,
    MatListModule,
    MatSelectModule,
    MatRadioModule,
    MatExpansionModule,
    MatFormFieldModule]
})

/**
 * Module used to define add-game elements
 */
export class AddGameModule { }
