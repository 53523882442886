import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ClinicalTest} from '../../../../data/model/clinicalTest';
import {ClinicalTestsCategory} from '../../../../data/model/clinicalTestsCategory';
import {CLINICALTESTSCATEGORIES} from '../../../../data/mock/CLINICALTESTSCATEGORIES';

@Component({
  selector: 'app-clinical-test-detail',
  templateUrl: './clinical-test-detail.component.html',
  styleUrls: ['./clinical-test-detail.component.css']
})

/**
 * This components contains the details of one clinical test
 */
export class ClinicalTestDetailComponent implements OnInit {

  /**
   * Event emitter to trigger when input changes
   */
  @Output() inputChanged = new EventEmitter();

  /**
   * Clinical test model
   */
  @Input() clinicalTest?: ClinicalTest;

  /**
   * List of different categories of clinical test available
   */
  clinicalTestsCategories: ClinicalTestsCategory[] = CLINICALTESTSCATEGORIES;

  constructor() {
  }

  ngOnInit(): void {
  }

  /**
   * Select and download a new file to add it in model
   * File is saved in an object containing File and its string representation (conversion of file in string object)
   * @param event file selected
   */
  onFileSelected(event: any): void {
    const fileReader = new FileReader();
    for (const file of event.target.files) {
      fileReader.readAsDataURL(file);
      fileReader.onload = (evt: any) => {
        try {
          this.clinicalTest.addFileBlobInJSON(file, evt.target.result);
        } catch (error) {
          console.log(error);
        }
      };
    }
  }

  /**
   * Change the type of current clinical test
   * @param checkUpType new type of the test
   */
  changeCheckUpType(checkUpType: string): void {
    this.clinicalTest.checkUpType = checkUpType;
    this.inputChanged.emit();
  }
}
