import {Component, OnInit} from '@angular/core';
import {AppComponent} from '../app/app.component';
import {PopupRulesGameDialogComponent} from '../../components/toolbox/clinical-cases-data-table/clinical-cases-data-table.component';
import {MatDialog} from '@angular/material';
import {FileOpener} from '@ionic-native/file-opener/ngx';
import {ClinicalCaseService} from '../../services/clinical-case.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css', '../app/app.component.css']
})
export class HomeComponent implements OnInit {
  constructor(public appComponent: AppComponent, private dialog: MatDialog, private fileOpener: FileOpener,
              private clinicalCaseService: ClinicalCaseService) {
    this.appComponent.HideButtonHome();
    this.appComponent.HideButtonHomefromTuto();
    this.appComponent.ShowButtonLogOut();
    this.appComponent.HideButtonLogOutFromTuto();
    this.appComponent.topTitle = ' Accueil';
  }

  ngOnInit() {
    history.pushState(null, null, location.href);
    window.onpopstate = () => {
      history.go(1);
    };
    window.addEventListener('beforeunload', (e) => {
      const confirmationMessage = '\o/';
      e.returnValue = confirmationMessage;     // Gecko, Trident, Chrome 34+
      return confirmationMessage;              // Gecko, WebKit, Chrome <34
    });
  }

  /**
   * Launch game from JSON selected by user.
   * @param files files selected by user
   */
  launchGameFromJSON(files: FileList): void {
    const file = files[0];
    const fileReader = new FileReader();
    fileReader.readAsText(file, 'UTF-8');
    fileReader.onload = () => {
      if (typeof fileReader.result === 'string') {
        this.clinicalCaseService.setClinicalCaseFromFile(fileReader.result);
        this.dialog.open(PopupRulesGameDialogComponent).disableClose = true;
      }
      fileReader.onerror = (error) => {
        console.log(error);
      };
    };
  }

  /**
   * Launch game from folder selected by user.
   * Folder should contain JSON (assuming it is called clinicalCase.JSON) file and associated files
   * @param event folder selected by user
   */
  launchGameFromFile(event): void {
    if (event.target.files.length > 0) {
      const files = event.target.files;
      for (let i = 0; i < files.length; ++i) {
        // Load JSON
        if (files[i].name === 'clinicalCase.JSON') {
          const fileReader = new FileReader();
          fileReader.readAsText(files[i], 'UTF-8');
          fileReader.onload = () => {
            if (typeof fileReader.result === 'string') {
              this.clinicalCaseService.setClinicalCaseFromFile(fileReader.result);
            }
          };
          fileReader.onerror = (error) => {
            console.log(error);
          };
        } else {

          // Load files
          const fileReader = new FileReader();
          fileReader.readAsDataURL(files[i]);
          fileReader.onload = (evt: any) => {
            const result = evt.target.result;
            // Manage here storing of file loaded
          };
          fileReader.onerror = (error) => {
            console.log(error);
          };
        }
      }
    }
    this.dialog.open(PopupRulesGameDialogComponent);
  }
}
