import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatTable} from '@angular/material/table';
import {Care} from '../../../data/model/care';
import {ClinicalCaseService} from '../../../services/clinical-case.service';

/**
 * Representation of one treatment (care) of clinical case
 * If the treatment (care) is choosen by expert, the boolean isSelectedByExpert is set to true
 */
export interface CareInterface {
  description: string;
  category: string;
  isSelectedByExpert: boolean;
}

@Component({
  selector: 'app-traitement',
  templateUrl: './care.component.html',
  styleUrls: ['./care.component.css']
})

/**
 * Represents treatment (care) of clinical case.
 */
export class CareComponent implements OnInit {

  /**
   * For interface
   */
  displayedColumns = ['cat', 'desc', 'avis', 'supp'];

  /**
   * Data used to fill treatment (care) table.
   * It represents what the user sees on the interface.
   * The link with clinical case model should be based on the content of this variable afterwards.
   */
  @Input() dataSource: CareInterface[] = [];

  /**
   * List of treatments (cares) proposed to the player
   */
  careGame: Care = new Care([], [], [], [], []);

  /**
   * List of treatments (cares) selected by experts
   */
  careSolution: Care = new Care([], [], [], [], []);

  @ViewChild(MatTable, {static: true}) table!: MatTable<any>;

  /**
   * Input field representing the description of the new care (treatment)
   */
  newCareDescription = '';

  /**
   * Input filed representing the category of the new care (treatment)
   */
  newCareCategory = '';

  /**
   * Small field filled when a message about new diagnostic input needs to be told (error message)
   */
  errorMsgAddRow = '';

  /**
   * Selected CareInterface object. (Line selected in table)
   */
  index: CareInterface;

  constructor(private clinicalCaseService: ClinicalCaseService) {
  }

  ngOnInit(): void {
    this.careGame = this.clinicalCaseService.getClinicalCase().careGame;
    this.careSolution = this.clinicalCaseService.getClinicalCase().careSolution;

    for (const ttt of this.careGame.orthopedicTreatmentList) {
      let isExpert = false;
      if (this.careSolution.orthopedicTreatmentList.find(elem => elem === ttt) !== undefined) {
        isExpert = true;
      }
      this.dataSource.push({category: 'Orthopédique', description: ttt, isSelectedByExpert: isExpert});
    }
    for (const ttt of this.careGame.medicalTreatmentList) {
      let isExpert = false;
      if (this.careSolution.medicalTreatmentList.find(elem => elem === ttt) !== undefined) {
        isExpert = true;
      }
      this.dataSource.push({category: 'Médical', description: ttt, isSelectedByExpert: isExpert});
    }
    for (const ttt of this.careGame.functionalTreatmentList) {
      let isExpert = false;
      if (this.careSolution.functionalTreatmentList.find(elem => elem === ttt) !== undefined) {
        isExpert = true;
      }
      this.dataSource.push({category: 'Fonctionnel', description: ttt, isSelectedByExpert: isExpert});
    }
    for (const ttt of this.careGame.surgicalTreatmentList) {
      let isExpert = false;
      if (this.careSolution.surgicalTreatmentList.find(elem => elem === ttt) !== undefined) {
        isExpert = true;
      }
      this.dataSource.push({category: 'Chirurgical', description: ttt, isSelectedByExpert: isExpert});
    }
    for (const ttt of this.careGame.otherTreatmentList) {
      let isExpert = false;
      if (this.careSolution.otherTreatmentList.find(elem => elem === ttt) !== undefined) {
        isExpert = true;
      }
      this.dataSource.push({category: 'Autre traitement', description: ttt, isSelectedByExpert: isExpert});
    }
  }

  /**
   * Add a new treatment (care)
   */
  addRow(): void {
    if (this.newCareDescription.length > 0 && this.newCareCategory.length > 0) {
      this.dataSource.push({description: this.newCareDescription, category: this.newCareCategory, isSelectedByExpert: false});
      this.table.renderRows();
      this.newCareDescription = '';
      this.newCareCategory = '';
      this.errorMsgAddRow = '';
    } else {
      this.errorMsgAddRow = 'Les champs nom et description doivent être remplis.';
    }
    this.saveDataSourceIntoModel();
  }

  /**
   * Remove a treatment (care)
   */
  removeRow(): void {
    this.dataSource = this.dataSource.filter(obj => obj !== this.index);
    this.table.renderRows();
    this.saveDataSourceIntoModel();
  }

  /**
   * Store selected table line for further management
   * @param element object of selected line
   */
  onClick(element: CareInterface): void {
    this.index = element;
  }

  /**
   * Handles changes of treatment (care) selected by the expert
   * @param checked whether treatment (care) is selected by experts or not
   * @param index index of treatment (care) selected
   */
  modifyCareSolution(checked: boolean, index: number): void {
    if (!checked) {
      this.dataSource[index].isSelectedByExpert = true;
    } else {
      this.dataSource[index].isSelectedByExpert = false;
    }
    this.saveDataSourceIntoModel();
  }

  /**
   * Change description of a treatment (care) already created
   */
  careDescriptionChanged(): void {
    this.saveDataSourceIntoModel();
  }

  /**
   * Interface between dataSource and clinical case model
   */
  saveDataSourceIntoModel(): void {
    this.careGame = new Care([], [], [], [], []);
    this.careSolution = new Care([], [], [], [], []);

    for (const ttt of this.dataSource) {
      if (ttt.category === 'Orthopédique') {
        if (ttt.isSelectedByExpert) {
          this.careSolution.addElement(ttt.category, ttt.description);
        }
        this.careGame.addElement(ttt.category, ttt.description);
      } else if (ttt.category === 'Médical') {
        if (ttt.isSelectedByExpert) {
          this.careSolution.addElement(ttt.category, ttt.description);
        }
        this.careGame.addElement(ttt.category, ttt.description);
      } else if (ttt.category === 'Fonctionnel') {
        if (ttt.isSelectedByExpert) {
          this.careSolution.addElement(ttt.category, ttt.description);
        }
        this.careGame.addElement(ttt.category, ttt.description);
      } else if (ttt.category === 'Chirurgical') {
        if (ttt.isSelectedByExpert) {
          this.careSolution.addElement(ttt.category, ttt.description);
        }
        this.careGame.addElement(ttt.category, ttt.description);
      } else if (ttt.category === 'Autre traitement') {
        if (ttt.isSelectedByExpert) {
          this.careSolution.addElement(ttt.category, ttt.description);
        }
        this.careGame.addElement(ttt.category, ttt.description);
      }
    }
    this.saveData();
  }

  /**
   * Save clinical case model
   */
  saveData(): void {
    this.clinicalCaseService.saveCares(this.careGame, this.careSolution);
  }
}
