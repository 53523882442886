/**
 * This class represent a file object
 */
export class FileExamenList {

  /**
   * File converted in string to be stored in JSON
   */
  fileInString: string;

  /**
   * File object
   */
  fileObject: File;

  constructor(pfileName: string) {
    this.fileInString = pfileName;
  }
}

/**
 * Helper class for files management
 */
export class Helper {

  /**
   * Get file extension given file name
   * @param fileName name of the file
   */
  public static findExtension(fileName: string): string {
    const splits = fileName.split('.');
    return splits[splits.length - 1];
  }

}


