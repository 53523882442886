import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class TextService {
  private text = new BehaviorSubject<string>('');
  cast = this.text.asObservable();

  constructor() {
  }

  editText(newText) {
    this.text.next(newText);
  }
}
