import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FileExamenList} from '../../../data/model/file';
import {CheckUp} from '../../../data/model/checkUp';
import {ClinicalCaseService} from '../../../services/clinical-case.service';

export interface ExamenPatientCategories {
  category: string;
  description: string;
}

@Component({
  selector: 'app-examen-patient',
  templateUrl: './check-up-observation.component.html',
  styleUrls: ['./check-up-observation.component.css']
})

/**
 * This component is used to represent patient exmination
 */
export class CheckUpObservationComponent implements OnInit {

  /**
   * Patient examination check up
   */
  checkUpObservationList: CheckUp[] = [new CheckUp(0, 'Observations visuelles', [], '', 0),
    new CheckUp(0, 'Examen palpatoire', [], '', 0)];

  /**
   * Categories to define how many expansion panels will be created and what is their content
   */
  categories: ExamenPatientCategories[] = [];

  /**
   * Current expansion pane opened (if one is opened, otherwise step === -1)
   */
  step = 0;

  @ViewChild('fileInput', {static: false}) el: ElementRef;

  constructor(private clinicalCaseService: ClinicalCaseService) {
  }

  ngOnInit(): void {
    this.categories.push({category: 'Observations visuelles', description: 'Description des observations'});
    this.categories.push({category: 'Examen palpatoire', description: 'Description des résultats de l\'examen'});
    this.checkUpObservationList = this.clinicalCaseService.getClinicalCase().checkUpObservationList;
  }

  /**
   * Set expansion pane opened
   */
  setStep(index: number): void {
    this.step = index;
  }

  /**
   * Go to next expansion pane
   */
  nextStep(): void {
    this.step++;
  }

  /**
   * Go to previous expansion pane
   */
  prevStep(): void {
    this.step--;
  }

  /**
   * Select and download a new file to add it in model
   * File is saved in an object containing File and its string representation (conversion of file in string object)
   * @param event file selected
   */
  onFileSelected(event: any): void {
    if (this.step !== -1) {
      const fileReader = new FileReader();
      for (const file of event.target.files) {
        fileReader.readAsDataURL(file);
        fileReader.onload = (evt: any) => {
          try {
            this.checkUpObservationList[this.step].addFileBlobInJSON(file, evt.target.result);
          } catch (error) {
            console.log(error);
          }
        };
      }
      this.saveData();
    }
  }

  /**
   * Save clinical case model
   */
  saveData(): void {
    this.clinicalCaseService.saveObservationCheckUp(this.checkUpObservationList);
  }

}
