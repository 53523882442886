import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from '../../app/app.component';
import {PlayGameComponent} from '../../play-game/play-game.component';
import {DisplayService} from '../../../services/display.service';
import {TextService} from '../../../services/text.service';
import {DiagnosticService} from '../../../services/diagnostic.service';
import {NgxGalleryService} from '../../../services/ngx-gallery.service';
import {NgxGalleryImage} from 'ngx-gallery';
import {ListService} from '../../../services/list.service';
import {ListButtonsService} from '../../../services/list-buttons.service';
import {ClinicalTest} from '../../../data/model/clinicalTest';
import {ClinicalCase} from '../../../data/model/clinicalCase';
import {ClinicalCaseService} from '../../../services/clinical-case.service';
import {CheckUp} from '../../../data/model/checkUp';
import {FileExamenList} from '../../../data/model/file';


@Component({
  selector: 'app-clinical-info-menu',
  templateUrl: './clinical-info-menu.component.html',
  styleUrls: ['./clinical-info-menu.component.css', '../../app/app.component.css']
})
export class ClinicalInfoMenuComponent implements OnInit {
  playGameComp: PlayGameComponent;
  appComp: AppComponent;

  /**
   * Clinical case model
   */
  @Input() clinicalCase: ClinicalCase;

  // list for ListService
  list: string[];

  // string for url for displayService
  url: string;
  url2: string;
  result: string;

  // string for TextService
  text: string;

  // string for DiagnosticService
  diagnostic = [];

  // table for rxjs
  galleryImages: NgxGalleryImage[];
  /*
    //service log
    log: Log;
    listLog:Log[]=[this.log];
  //Timer Service
    timer: Timer;
    minutes: number;
    secondes: number;
  */

  constructor(private playGameComponent: PlayGameComponent,
              private appComponent: AppComponent,
              private displayService: DisplayService,
              private textService: TextService,
              private diagnosticService: DiagnosticService,
              private rxjsService: NgxGalleryService,
              private listService: ListService,
              /*private logService: LogService,
              private timerService: TimerService*/
              private listButtonsService: ListButtonsService,
              private clinicalCaseService: ClinicalCaseService) {
    this.playGameComp = playGameComponent;
    this.appComp = appComponent;
  }

  ngOnInit() {
    // Subscribe to clinical case service
    this.clinicalCase = this.clinicalCaseService.getClinicalCase();

    // subscribe to DisplayService
    this.displayService.castUrl.subscribe(url => this.url = url);
    this.displayService.castResult.subscribe(result => this.result = result);
    this.displayService.castUrl2.subscribe(url2 => this.url2 = url2);

    // subscribe to textService
    this.textService.cast.subscribe(text => this.text = text);

    // subscribe to diagnosticService
    this.rxjsService.cast.subscribe(images => this.galleryImages = images);

    // subscribe to ListService
    this.listService.cast.subscribe(list => this.list = list);
    /*
        //subscribe log service
        this.logService.cast.subscribe(list => this.listLog = list);
        this.logService.castLog.subscribe(log => this.log = log);

        //subscribe timer service
        this.timerService.castTimer.subscribe(timer => this.timer = timer);
        this.timerService.castMinutes.subscribe(minutes => this.minutes = minutes);
        this.timerService.castSecondes.subscribe(secondes => this.secondes = secondes);
    */
  }

  /**
   * Display patient information content
   */
  launchPatientInfos() {
    if (this.clinicalCase.patient.InfoPatientList.length === 0) {
      this.textService.editText('Pas d\'informations disponibles');
    } else {
      this.textService.editText(this.clinicalCase.patient.InfoPatientList);
    }
  }

  /**
   * Display patient activities information
   */
  launchActivitiesList() {
    if (this.clinicalCase.patient.ActivitiesList.length === 0) {
      this.textService.editText('Pas d\'informations disponibles');
    } else {
      this.textService.editText(this.clinicalCase.patient.ActivitiesList);
    }
  }

  /**
   * Display patient pain information
   */
  launchPainList() {
    if (this.clinicalCase.patient.PainList.length === 0) {
      this.textService.editText('Pas d\'informations disponibles');
    } else {
      this.textService.editText(this.clinicalCase.patient.PainList);
    }
  }

  /**
   * Display patient living place information
   */
  launchLivingPlaceList() {
    if (this.clinicalCase.patient.LivingPlaceList.length === 0) {
      this.textService.editText('Pas d\'informations disponibles');
    } else {
      this.textService.editText(this.clinicalCase.patient.LivingPlaceList);
    }
  }

  /**
   * Display patient medical history information
   */
  launchMedicalHistoryList() {
    if (this.clinicalCase.patient.MedicalHistoryList.length === 0) {
      this.textService.editText('Pas d\'informations disponibles');
    } else {
      this.textService.editText(this.clinicalCase.patient.MedicalHistoryList);
    }
  }

  /**
   * Display patient social situation information
   */
  launchSocialSituationList() {
    if (this.clinicalCase.patient.SocialSituationList.length === 0) {
      this.textService.editText('Pas d\'informations disponibles');
    } else {
      this.textService.editText(this.clinicalCase.patient.SocialSituationList);
    }
  }

  /**
   * Display patient trauma information
   */
  launchInfoTrauma() {
    if (this.clinicalCase.ListInfoTrauma.length === 0) {
      this.textService.editText('Pas d\'informations disponibles');
    } else {
      this.textService.editText(this.clinicalCase.ListInfoTrauma);
    }
  }

  /**
   * Display patient imaging (checkUp image) exam details given exam type
   * @param type type of the exam
   */
  launchCheckUpImages(type: string) {
    this.rxjsService.editImages([]);
    const index = this.findIndexOfCheckUpFromList(this.clinicalCase.checkUpImagesList, type);
    if (index !== -1) {
      if (this.clinicalCase.checkUpImagesList[index].result.length === 0
        && this.clinicalCase.checkUpImagesList[index].result === ''
        && this.clinicalCase.checkUpImagesList[index].media.length === 0) {
        this.displayService.editResult(['Pas d\'examen disponible pour cette catégorie']);
      } else {
        this.displayService.editResult(this.clinicalCase.checkUpImagesList[index].result);
        this.displayImages(this.clinicalCase.checkUpImagesList[index].media);
      }
    } else {
      this.displayService.editResult(['Pas d\'examen disponible pour cette catégorie']);
    }
    this.rxjsService.editImages(this.galleryImages);
  }

  /**
   * Display patient examination test details given its type
   * @param type type of exam
   */
  launchExamination(type: string) {
    this.rxjsService.editImages([]);
    const index = this.findIndexOfCheckUpFromList(this.clinicalCase.checkUpObservationList, type);
    if (index !== -1) {
      if (this.clinicalCase.checkUpObservationList[index].result.length === 0
        && this.clinicalCase.checkUpObservationList[index].result === ''
        && this.clinicalCase.checkUpObservationList[index].media.length === 0) {
        this.displayService.editResult(['Pas d\'observation disponible pour cette catégorie']);
      } else {
        this.displayService.editResult(this.clinicalCase.checkUpObservationList[index].result);
        this.displayImages(this.clinicalCase.checkUpObservationList[index].media);
      }
    } else {
      this.displayService.editResult(['Pas d\'observation disponible pour cette catégorie']);
    }
    this.rxjsService.editImages(this.galleryImages);
  }

  /**
   * Display biological test in interface
   * @param type type of test
   */
  launchBiology(type: string) {
    this.rxjsService.editImages([]);
    const index = this.findIndexOfCheckUpFromList(this.clinicalCase.checkUpBiologyList, type);
    if (index !== -1) {
      if (this.clinicalCase.checkUpBiologyList[index].result.length === 0
        && this.clinicalCase.checkUpBiologyList[index].result === ''
        && this.clinicalCase.checkUpBiologyList[index].media.length === 0) {
        this.displayService.editResult(['Pas d\'examen disponible pour cette catégorie']);
      } else {
        this.displayService.editResult(this.clinicalCase.checkUpBiologyList[index].result);
        this.displayImages(this.clinicalCase.checkUpImagesList[index].media);
      }
    } else {
      this.displayService.editResult(['Pas d\'examen disponible pour cette catégorie']);
    }
    this.rxjsService.editImages(this.galleryImages);
  }

  /**
   * Display clinical test content in interface given a type of test
   * @param type type of clinical test
   */
  launchClinicalTest(type: string) {
    this.rxjsService.editImages([]);
    const clinicalTests = this.findClinicalTestsFromList(this.clinicalCase.checkUpClinicalTestList, type);
    if (clinicalTests.length !== 0) {
      this.listButtonsService.setCheckUp(clinicalTests);
      this.playGameComp.ShowComponentDisplayHide();
      this.playGameComp.ShowComponentButtonsVisible();
    } else {
      this.displayService.editResult(['Pas d\'examen disponible pour cette catégorie']);
      this.playGameComp.ShowComponentDisplayVisible();
      this.playGameComp.ShowComponentButtonsHide();
    }
    this.playGameComp.ShowComponentListHide();
    this.playGameComp.ShowComponentTextHide();
  }

  /**
   * Get all clinical tests of a certain category from a list
   * @param array clinical tests list
   * @param valueSearched category of test wanted
   */
  findClinicalTestsFromList(array: Array<ClinicalTest>, valueSearched: string): Array<ClinicalTest> {
    let indexes = [];
    for (const elem of array) {
      if (elem.checkUpType === valueSearched) {
        indexes.push(elem);
      }
    }
    return indexes;
  }

  /**
   * Get the position of a checkup in a list given its type
   * @param array check ups list
   * @param valueSearched type of checkup searched
   */
  findIndexOfCheckUpFromList(array: Array<CheckUp>, valueSearched: string): number {
    for (let i = 0; i < array.length; i++) {
      if (array[i].checkUpType === valueSearched) {
        return i;
      }
    }
    return -1;
  }

  /**
   * Display files in interface from a list of files given their url
   * @param filesList list of files
   */
  displayImages(filesList: FileExamenList[]): void {
    for (const file of filesList) {
      if (file !== null) {
        this.displayService.editUrl(file.fileInString);
        this.galleryImages.push({
          small: this.url,
          medium: this.url,
          big: this.url,
        }, );
        this.rxjsService.editImages(this.galleryImages);
      }
    }
  }
}
