import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

import {Timer} from '../data/model/timer';
import {Log} from '../data/model/log';

@Injectable({
  providedIn: 'root'
})

export class LogService {
  timer = new Timer(0, 0);
  log: Log = {
    id: 'depart',
    temps: this.timer,
  };
  log2: Log = {
    id: '',
    temps: this.timer,
  };
  private listLogExamen = new BehaviorSubject<[Log]>([this.log]);
  private Log = new BehaviorSubject<Log>(this.log2);
  cast = this.listLogExamen.asObservable();
  castLog = this.Log.asObservable();

  constructor() {
  }

  editListLogExamen(newList) {
    this.listLogExamen.next(newList);
  }

  editLog(newLog) {
    this.Log.next(newLog);
  }
}
