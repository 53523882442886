import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AppComponent} from '../app/app.component';
import {Users} from '../../data/model/users';
import {FormControl, Validators} from '@angular/forms';
import {USER} from '../../data/mock/USER';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css', '../app/app.component.css']
})
export class LoginComponent implements OnInit {

  constructor(public router: Router, public appComponent: AppComponent, ) {
    this.appComponent.HideButtonHome();
    this.appComponent.HideButtonLogOut();
    this.appComponent.topTitle = ' Bienvenue dans le serious game Cocoprint !';
  }
  color: string;
  // users: Users[];
  username: string;
  password: string;
  email = new FormControl('', [Validators.email]);
  currentUser: Users;
  answerConnection = false;
  answerWrongConnection: string;

  a = 0;


  getErrorMessageEmail() {
    return this.email.hasError('email') ? 'Ce n\'est pas une adresse email valide' :
      '';
  }

  ngOnInit() {
    
    // ARG NOOOOO
    // Externalize and load from external file
    // At least use import  *  as CryptoJS from  'crypto-js';
    localStorage.setItem('user', JSON.stringify(USER));

  }

  login(): void {
    for (let i = 0; i < USER.length; i++) {
        // ARG NOOOO
      if (this.username == USER[i].username && this.password == USER[i].password) {
        this.appComponent.launchHome();
        break;
      } else if (this.username !== USER[i].username || this.password !== USER[i].password) {
        this.answerConnection = true;
        this.answerWrongConnection = 'Identifiant et/ou mot de passe invalide(s)';
      }
    }
  }
}

