import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NotesListService {

  private listNotes = new BehaviorSubject<string>('');
  cast = this.listNotes.asObservable();

  constructor() {
  }

  editList(newListNotes) {
    this.listNotes.next(newListNotes);
  }

}


