import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {Timer} from '../data/model/timer';

@Injectable({
  providedIn: 'root'
})
export class TimerService {

  timers: Timer;
  private minutes = new BehaviorSubject<number>(0);
  private secondes = new BehaviorSubject<number>(0);
  private timer = new BehaviorSubject<Timer>(this.timers);

  castMinutes = this.minutes.asObservable();
  castSecondes = this.secondes.asObservable();
  castTimer = this.timer.asObservable();

  constructor() {
  }

  editSecondes(newSecondes) {
    this.secondes.next(newSecondes);
  }

  editMinutes(newMinutes) {
    this.minutes.next(newMinutes);
  }

  editTimer(newTimer) {
    this.timer.next(newTimer);
  }

  stopTimer() {
    this.stopTimer();
  }
}
