import {Injectable, Input} from '@angular/core';
import {ClinicalCase} from '../data/model/clinicalCase';
import {Patient} from '../data/model/patient';
import {CheckUp} from '../data/model/checkUp';
import {ClinicalTest} from '../data/model/clinicalTest';
import {Diagnostic} from '../data/model/diagnostic';
import {Care} from '../data/model/care';
import * as FileSaver from 'file-saver';
import * as JSZip from 'jszip';
import {ClinicalCaseServiceModule} from '../modules/clinical-case-service/clinical-case-service.module';
import {Timer} from '../data/model/timer';

@Injectable({
  providedIn: ClinicalCaseServiceModule
})

/**
 * Service to manage current clinical case
 */
export class ClinicalCaseService {

  @Input() private clinicalCase: ClinicalCase;

  constructor() {
    this.initClinicalCase();
  }

  /**
   * Initialize clinical case with default values
   */
  initClinicalCase(): void {
    this.clinicalCase = {
      idCC: 0,
      idCreator: '',

      name: '',
      difficulty: '',
      fields: '',
      dysfunctionDate: new Date(),
      careDate: new Date(),

      careContext: '',
      ListInfoTrauma: [],

      // List of imaging exams
      checkUpImagesList: [new CheckUp(0, 'Examen de radiographie', [], '', 10),
        new CheckUp(0, 'Examen de radiographie dynamique', [], '', 10),
        new CheckUp(0, 'Examen de scanner', [], '', 10),
        new CheckUp(0, 'Examen d\'arthroscanner', [], '', 10),
        new CheckUp(0, 'Examen d\'IRM', [], '', 10),
        new CheckUp(0, 'Examen de scintigraphie', [], '', 10)],
      // Biological exams
      checkUpBiologyList: [new CheckUp(0, 'Biologique', [], '', 0)],
      checkUpObservationList: [new CheckUp(0, 'Observations visuelles', [], '', 0),
        new CheckUp(0, 'Examen palpatoire', [], '', 0)], //
      // Clinical tests
      checkUpClinicalTestList: [],

      diagnosticGame: new Diagnostic([]),
      diagnosticSolution: new Diagnostic([]),

      careSolution: new Care([], [], [], [], []),
      careGame: new Care([], [], [], [], []), // traitements

      maxGameTime: new Timer(0, 0),

      patient: new Patient('', '', '', '', '', ''), // réaliser l'patient-info

      logCorrection: '',
    };
  }

  /**
   * Download clinical case as JSON file
   */
  downloadClinicalCase(): void {
    const jsonToWrite: string = JSON.stringify(this.clinicalCase, null, '\t');
    const blob = new Blob([jsonToWrite], {type: 'JSON/plain;charset=utf-8'});
    const fileName = this.clinicalCase.name.replace(/ /g, '') !== '' ? this.clinicalCase.name.replace(/ /g, '_') : 'clinicalCase';
    FileSaver.saveAs(blob, fileName + '.JSON');
  }

  /**
   * Download clinical case as a zip.
   * Zip will contain the clinical case as json file and a folder 'caseFiles' with files downloaded of clinical case
   */
  downloadZipClinicalCase(): void {
    // 1- Create archive
    const zip = new JSZip();

    // 2- Create folder with files
    const caseFiles = zip.folder('caseFiles');
    if (caseFiles !== null) {

      // ---Patient exam files
      for (const patientExam of this.clinicalCase.checkUpObservationList) {
        for (const file of patientExam.media) {
          caseFiles.file(file.fileObject.name, file.fileObject, {base64: true});
        }
      }

      // ---Clinical test files
      for (let index = 0; index < this.clinicalCase.checkUpClinicalTestList.length; index++) {
        this.clinicalCase.checkUpClinicalTestList[index].generateFileNames(index.toString());
        for (const file of this.clinicalCase.checkUpClinicalTestList[index].media) {
          caseFiles.file(file.fileObject.name, file.fileObject, {base64: true});
        }
      }

      // ---Biology exam files
      for (const file of this.clinicalCase.checkUpBiologyList[0].media) {
        caseFiles.file(file.fileObject.name, file.fileObject, {base64: true});
      }
      // ---Image exam files
      for (const checkUp of this.clinicalCase.checkUpImagesList) {
        for (const file of checkUp.media) {
          caseFiles.file(file.fileObject.name, file.fileObject, {base64: true});
        }
      }
    }

    // 3- Add json to archive
    // first, convert object model to string in json
    const jsonToWrite: string = JSON.stringify(this.clinicalCase, null, '\t');
    // the, convert string to blob to create the file
    const blob = new Blob([jsonToWrite], {type: 'JSON/plain;charset=utf-8'});
    zip.file('clinicalCase.JSON', blob);

    // 4- Save archive
    zip.generateAsync({type: 'blob'})
      .then((content: any) => {
        const date = new Date();
        FileSaver.saveAs(content, 'Cocoprint_clinical_case_' + date.toISOString() + '.zip');
      });
  }

  /**
   * Save general information into model
   * @param name case name
   * @param fields case field of application
   * @param idCreator creator id
   * @param difficulty case difficulty
   * @param careContext clinical case context
   */
  saveGeneralInfo(name: string, fields: string, idCreator: string, difficulty: string, careContext: string): void {
    this.clinicalCase.name = name;
    this.clinicalCase.fields = fields;
    this.clinicalCase.idCreator = idCreator;
    this.clinicalCase.difficulty = difficulty;
    this.clinicalCase.careContext = careContext;
  }

  /**
   * Save patient general information into model
   * @param patient patient general information
   * @param ListInfoTrauma trauma description
   */
  savePersonalInfo(patient: Patient, ListInfoTrauma: string[]): void {
    this.clinicalCase.patient = patient;
    this.clinicalCase.ListInfoTrauma = ListInfoTrauma;
  }

  /**
   * Save patient examination information into model
   * @param checkUpObservationList patient examination
   */
  saveObservationCheckUp(checkUpObservationList: CheckUp[]): void {
    this.clinicalCase.checkUpObservationList = checkUpObservationList;
  }

  /**
   * Save clinical test information into model
   * @param clinicalTests clinical tests
   */
  saveClinicalTests(clinicalTests: ClinicalTest[]): void {
    this.clinicalCase.checkUpClinicalTestList = clinicalTests;
  }

  /**
   * Save imaging exam information into model
   * @param checkUps imaging exams
   */
  saveCheckUpImage(checkUps: CheckUp[]): void {
    this.clinicalCase.checkUpImagesList = checkUps;
  }

  /**
   * Save biology exam information into model
   * @param checkUps biology exams
   */
  saveCheckUpBiology(checkUps: CheckUp[]): void {
    this.clinicalCase.checkUpBiologyList = checkUps;
  }

  /**
   * Save diagnostics into model
   * @param diagnosticGame diagnostics suggested to played
   * @param diagnosticSolution diagnostics choosen by experts
   * @param logCorrection expected approach to solve the case
   */
  saveDiagnostics(diagnosticGame: Diagnostic, diagnosticSolution: Diagnostic, logCorrection: string): void {
    this.clinicalCase.diagnosticGame = diagnosticGame;
    this.clinicalCase.diagnosticSolution = diagnosticSolution;
    this.clinicalCase.logCorrection = logCorrection;
  }

  /**
   * Save treatments into model
   * @param careGame treatments suggested to player
   * @param careSolution treatments choosen by experts
   */
  saveCares(careGame: Care, careSolution: Care): void {
    this.clinicalCase.careGame = careGame;
    this.clinicalCase.careSolution = careSolution;
  }

  /**
   * Returns instance of current clinical case
   */
  getClinicalCase(): ClinicalCase {
    return this.clinicalCase;
  }

  /**
   * This method is used to set current clinical case from JSON clinical case.
   * JSON will be parsed and then data will be set
   * @param content JSON to parse
   */
  setClinicalCaseFromFile(content: string): void {
    const clinicalCaseJSON = JSON.parse(content);

    this.clinicalCase.idCC = clinicalCaseJSON.idCC;
    this.clinicalCase.idCreator = clinicalCaseJSON.idCreator;

    this.clinicalCase.name = clinicalCaseJSON.name;
    this.clinicalCase.difficulty = clinicalCaseJSON.difficulty;
    this.clinicalCase.fields = clinicalCaseJSON.fields;
    this.clinicalCase.dysfunctionDate = new Date(clinicalCaseJSON.dysfunctionDate);
    this.clinicalCase.careDate = new Date (clinicalCaseJSON.careDate);

    this.clinicalCase.careContext = clinicalCaseJSON.careContext;
    this.clinicalCase.ListInfoTrauma = clinicalCaseJSON.ListInfoTrauma;

    this.clinicalCase.checkUpImagesList = [];
    for (const checkUp of clinicalCaseJSON.checkUpImagesList) {
      this.clinicalCase.checkUpImagesList.push(
        new CheckUp(checkUp.idCC, checkUp.checkUpType, checkUp.media, checkUp.result, checkUp.time));
    }
    this.clinicalCase.checkUpBiologyList = [];
    for (const checkUp of clinicalCaseJSON.checkUpBiologyList) {
      this.clinicalCase.checkUpBiologyList.push(
        new CheckUp(checkUp.idCC, checkUp.checkUpType, checkUp.media, checkUp.result, checkUp.time));
    }
    this.clinicalCase.checkUpObservationList = [];
    for (const checkUp of clinicalCaseJSON.checkUpObservationList) {
      this.clinicalCase.checkUpObservationList.push(
        new CheckUp(checkUp.idCC, checkUp.checkUpType, checkUp.media, checkUp.result, checkUp.time));
    }
    this.clinicalCase.checkUpClinicalTestList = [];
    for (const checkUp of clinicalCaseJSON.checkUpClinicalTestList) {
      this.clinicalCase.checkUpClinicalTestList.push(
        new ClinicalTest(checkUp.idCC, checkUp.checkUpType, checkUp.media, checkUp.result, checkUp.title, checkUp.time));
    }

    this.clinicalCase.diagnosticGame.diagnosticList = clinicalCaseJSON.diagnosticGame.diagnosticList;
    this.clinicalCase.diagnosticSolution.diagnosticList = clinicalCaseJSON.diagnosticSolution.diagnosticList;

    this.clinicalCase.careGame.orthopedicTreatmentList = clinicalCaseJSON.careGame.orthopedicTreatmentList;
    this.clinicalCase.careGame.functionalTreatmentList = clinicalCaseJSON.careGame.functionalTreatmentList;
    this.clinicalCase.careGame.medicalTreatmentList = clinicalCaseJSON.careGame.medicalTreatmentList;
    this.clinicalCase.careGame.surgicalTreatmentList = clinicalCaseJSON.careGame.surgicalTreatmentList;
    this.clinicalCase.careGame.otherTreatmentList = clinicalCaseJSON.careGame.otherTreatmentList;

    this.clinicalCase.careSolution.orthopedicTreatmentList = clinicalCaseJSON.careSolution.orthopedicTreatmentList;
    this.clinicalCase.careSolution.functionalTreatmentList = clinicalCaseJSON.careSolution.functionalTreatmentList;
    this.clinicalCase.careSolution.medicalTreatmentList = clinicalCaseJSON.careSolution.medicalTreatmentList;
    this.clinicalCase.careSolution.surgicalTreatmentList = clinicalCaseJSON.careSolution.surgicalTreatmentList;
    this.clinicalCase.careSolution.otherTreatmentList = clinicalCaseJSON.careSolution.otherTreatmentList;

    this.clinicalCase.patient.InfoPatientList = clinicalCaseJSON.patient.InfoPatientList;
    this.clinicalCase.patient.ActivitiesList = clinicalCaseJSON.patient.ActivitiesList;
    this.clinicalCase.patient.LivingPlaceList = clinicalCaseJSON.patient.LivingPlaceList;
    this.clinicalCase.patient.MedicalHistoryList = clinicalCaseJSON.patient.SocialSituationList;
    this.clinicalCase.patient.SocialSituationList = clinicalCaseJSON.patient.SocialSituationList;
    this.clinicalCase.patient.PainList = clinicalCaseJSON.patient.PainList;

    this.clinicalCase.maxGameTime = new Timer(0, 0);
    this.clinicalCase.logCorrection = clinicalCaseJSON.logCorrection;

    console.log(this.clinicalCase);
  }

}
