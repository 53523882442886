import {FileExamenList, Helper} from './file';

/**
 * This class represents an Examination, also called 'check up'.
 */
export class CheckUp {

  /**
   * Clinical case id
   */
  idCC: number;

  /**
   * Type of check up
   */
  checkUpType: string;

  /**
   * List of files of the check up
   */
  media: FileExamenList[];

  /**
   * Result, description of the check up
   */
  result: string;

  /**
   * @ignore
   */
  time: number;

  /**
   * Title of the check up
   * (this field might be unused, can be deleted after further checks)
   */
  title: string[];

  constructor(idCC: number, checkUpType: string, media: FileExamenList[], result: string, time: number) {
    this.idCC = idCC;
    this.checkUpType = checkUpType;
    this.media = media;
    this.result = result;
    this.time = time;
  }

  /**
   * Add a new file to file list, using storage in JSON of filename to get in database or zip folder
   * @param file file object
   * @param category file category. Used to rename file for storage to be uniquely identified.
   * Categories are 'check_up_biology', 'check_up_image', 'check_up_observation', 'clincial_test_TESTCATEGORY'
   */
  addFile(file: File, category: string) {
    this.media.push({
      fileInString: this.media.length + '_' + category + '.' + Helper.findExtension(file.name),
      fileObject: file
    });
  }

  /**
   * Add a new file to file list, using storage in JSON of converted file in string
   * @param file file object
   * @param fileInString file object converted in string
   */
  addFileBlobInJSON(file: File, fileInString: string) {
    this.media.push({
      fileInString: fileInString,
      fileObject: file
    });
  }

}
