import {Injectable} from '@angular/core';
import {NgxGalleryImage} from 'ngx-gallery';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class NgxGalleryService {

  private images = new BehaviorSubject<NgxGalleryImage[]>([]);
  cast = this.images.asObservable();

  constructor() {
  }

  editImages(images) {
    this.images.next(images);
  }
}
