import {Component, Inject} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DialogData} from '../play-game/play-game.component';
import {CLINICALCASE} from '../../data/mock/CLINICALCASE';
import {NotesListService} from '../../services/notes-list.service';
import {SelectedOptionService} from '../../services/selected-option.service';
import {TextService} from '../../services/text.service';
import {LogService} from '../../services/log.service';
import {Log} from '../../data/model/log';
import {Timer} from '../../data/model/timer';
import {TimerService} from '../../services/timer.service';
import {PlatformLocation} from '@angular/common';
import {ClinicalCaseService} from '../../services/clinical-case.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

/**
 * The main component of the application. Here are implemented all the navigation methods.
 */
export class AppComponent {
  isButtonHomeVisible: boolean;
  isButtonLogOutVisible: boolean;
  isButtonHomeFromTutoVisible: boolean;
  isButtonLogOutFromTutoVisible: boolean;
  title = 'SGP';
  topTitle = ' ';
  log: Log;
  listLog: Log[] = [this.log];
  timer: Timer;
  minutes: number;
  seconds: number;
  private notes: string;
  private selectedDiag;
  private text: string;

  constructor(public router: Router, private dialog: MatDialog, private notesService: NotesListService,
              private diagnosticService: SelectedOptionService, private textService: TextService,
              private logService: LogService, private timerService: TimerService,
              private location: PlatformLocation, private clinicalCaseService: ClinicalCaseService) {
    this.location.onPopState(() => {
      this.dialog.open(PopupForbiddenBackForwardDialogComponent).disableClose = true;
    });
    this.isButtonHomeVisible = false;
    this.isButtonLogOutVisible = false;
    this.isButtonHomeFromTutoVisible = false;
    this.isButtonLogOutFromTutoVisible = false;

    // subscribe to noteService
    this.notesService.cast.subscribe(notes => this.notes = notes);
    // subscribe to diagnostic service
    this.diagnosticService.cast.subscribe(selectedDiag => this.selectedDiag = selectedDiag);
    // subscribe to text service
    this.textService.cast.subscribe(text => this.text = text);
    // subscribe to log service
    this.logService.cast.subscribe(log => this.listLog = log);
    this.logService.castLog.subscribe(log => this.log = log);
    // subscribe timer service
    this.timerService.castTimer.subscribe(timer => this.timer = timer);
    this.timerService.castMinutes.subscribe(minutes => this.minutes = minutes);
    this.timerService.castSecondes.subscribe(seconds => this.seconds = seconds);

  }

  launchReturnHome() {
    this.dialog.open(PopupReturnHomeDialogComponent).disableClose = true;
    // ARG, remove this from here please, at least load it from somewhere else.
    localStorage.setItem('cc', JSON.stringify(CLINICALCASE));
    this.clinicalCaseService.initClinicalCase();
    localStorage.removeItem('note');
    this.notesService.editList('');
    this.textService.editText('');
    this.logService.editListLogExamen([]);

  }

  launchReturnHomeFromTutorial() {
    this.dialog.open(PopupHomeFromTutorialDialogComponent).disableClose = true;
    localStorage.setItem('cc', JSON.stringify(CLINICALCASE));
    this.clinicalCaseService.initClinicalCase();
    localStorage.removeItem('note');
    this.notesService.editList('');
    this.textService.editText('');
  }

  launchHome() {
    this.router.navigate(['home']);
    localStorage.setItem('cc', JSON.stringify(CLINICALCASE));
    this.clinicalCaseService.initClinicalCase();
    localStorage.removeItem('note');
    this.notesService.editList('');
    this.textService.editText('');
    this.logService.editListLogExamen([]);
  }

  launchLogin() {
    this.router.navigate(['login']);
    localStorage.clear();
    this.notesService.editList('');
    this.textService.editText('');
    this.logService.editListLogExamen([]);
  }

  ShowButtonHome() {
    this.isButtonHomeVisible = true;
  }

  HideButtonHome() {
    this.isButtonHomeVisible = false;
  }

  ShowButtonHomefromTuto() {
    this.isButtonHomeFromTutoVisible = true;
  }

  HideButtonHomefromTuto() {
    this.isButtonHomeFromTutoVisible = false;
  }

  ShowButtonLogOut() {
    this.isButtonLogOutVisible = true;
  }

  HideButtonLogOut() {
    this.isButtonLogOutVisible = false;
  }

  ShowButtonLogOutFromTuto() {
    this.isButtonLogOutFromTutoVisible = true;
  }

  HideButtonLogOutFromTuto() {
    this.isButtonLogOutFromTutoVisible = false;
  }

  launchLogOut() {
    this.dialog.open(PopupLogOutDialogComponent).disableClose = true;
  }

  launchLogOutFromTutorial() {
    this.dialog.open(PopupLogOutFromTutorialDialogComponent).disableClose = true;
  }

  toggle(event) {
    this.logService.editLog({
      id: event.target.id,
      temps: new Timer(this.timer.getMinutes, this.timer.getSecondes),
    });
    this.listLog.push(this.log);
    console.log(this.listLog);
    this.logService.editListLogExamen(this.listLog);
  }

}


@Component({
  selector: 'app-root',
  templateUrl: './PopupLogOutDialog.html',
})
/**
 * Logs the user out of Cocoprint
 */
export class PopupLogOutDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupLogOutDialogComponent>, public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private clinicalCaseService: ClinicalCaseService) {
  }

  logOut() {
    this.clinicalCaseService.initClinicalCase();
  }
}


@Component({
  selector: 'app-root',
  templateUrl: './PopupLogOutFromTutoDialog.html',
})
/**
 * Logs the user out of Cocoprint from the tutorial
 */
export class PopupLogOutFromTutorialDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupLogOutFromTutorialDialogComponent>, public router: Router,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }
}


@Component({
  selector: 'app-root',
  templateUrl: './PopupReturnHomeDialog.html',
})
/**
 * Redirects the user to the home page
 */
export class PopupReturnHomeDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupReturnHomeDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData, private clinicalCaseService: ClinicalCaseService) {
  }

  returnHome() {
    this.clinicalCaseService.initClinicalCase();
  }
}


@Component({
  selector: 'app-root',
  templateUrl: './PopupHomefromTutoDialog.html',
})
/**
 * Redirects the user to the home page from the tutorial
 */
export class PopupHomeFromTutorialDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupHomeFromTutorialDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }
}


@Component({
  selector: 'app-root',
  templateUrl: './PopupForbiddenBackForwardDialog.html',
})
/**
 * Informs the user the arrows of the browser can't be used
 */
export class PopupForbiddenBackForwardDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupForbiddenBackForwardDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }
}
