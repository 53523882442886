import {Component, OnInit} from '@angular/core';
import {NotesListService} from '../../../services/notes-list.service';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css', '../../app/app.component.css']
})
export class NotesComponent implements OnInit {
  notes: string;

  constructor(private notesService: NotesListService) {
  }

  ngOnInit() {
    // subscribe to noteService
    this.notesService.cast.subscribe(notes => this.notes = notes);
  }

  logForm() {
    localStorage.setItem('note', JSON.stringify(this.notes));
  }


}
