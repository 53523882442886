import {Component, Input, OnInit} from '@angular/core';
import {CheckUp} from '../../../data/model/checkUp';
import {ClinicalCaseService} from '../../../services/clinical-case.service';

@Component({
  selector: 'app-check-up-biology',
  templateUrl: './check-up-biology.component.html',
  styleUrls: ['./check-up-biology.component.css']
})

/**
 * Component used to represent biology exam
 */
export class CheckUpBiologyComponent implements OnInit {

  /**
   * Biology checkUp
   */
  @Input() checkUps: CheckUp[] = [new CheckUp(0, 'Biologique', [], '', 0)];

  constructor(private clinicalCaseService: ClinicalCaseService) {
  }

  ngOnInit(): void {
    this.checkUps = this.clinicalCaseService.getClinicalCase().checkUpBiologyList;
  }

  /**
   * Select and download a new file to add it in model
   * File is saved in an object containing File and its string representation (conversion of file in string object)
   * @param event file selected
   */
  onFileSelected(event: any): void {
    const fileReader = new FileReader();
    for (const file of event.target.files) {
      fileReader.readAsDataURL(file);
      fileReader.onload = (evt: any) => {
        try {
          this.checkUps[0].addFileBlobInJSON(file, evt.target.result);
        } catch (error) {
          console.log(error);
        }
      };
    }
    this.saveData();
  }

  /**
   * Save clinical case model
   */
  saveData(): void {
    this.clinicalCaseService.saveCheckUpBiology(this.checkUps);
  }
}
