import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

// import components
import {LoginComponent} from '../components/login/login.component';
import {HomeComponent} from '../components/home/home.component';
import {PlayGameComponent} from '../components/play-game/play-game.component';
import {AddGameComponent} from '../components/add-game/add-game.component';
import {TutoComponent} from '../components/toolbox/tuto/tuto.component';

/**
 * Define routes of the web server.
 */
const routes: Routes = [
  {path: 'home', component: HomeComponent},
  {path: 'addGame', component: AddGameComponent},
  {path: 'login', component: LoginComponent},
  {path: 'playGame', component: PlayGameComponent},
  {path: '', component: LoginComponent},
  {path: 'tuto', component: TutoComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {


}
