export class Timer {
  private minutes = 0;
  private secondes = 0;
  private totalSecondes = 0;
  private timer;


  constructor(minutes: number, secondes: number) {
    this.minutes = minutes;
    this.secondes = secondes;
  }

  start() {
    this.timer = setInterval(() => {
      this.minutes = Math.floor(++this.totalSecondes / 60);
      this.secondes = this.totalSecondes - this.minutes * 60;
    }, 1000);
  }

  stop() {
    localStorage.setItem('minutes', JSON.stringify(this.getMinutes));
    localStorage.setItem('secondes', JSON.stringify(this.getSecondes));
    clearInterval(this.timer);
  }

  get getMinutes(): number {
    return this.minutes;
  }

  get getSecondes(): number {
    return this.secondes;
  }
}
