import {Component, OnInit} from '@angular/core';
import {NgxGalleryImage} from 'ngx-gallery';
import {ListButtonsService} from '../../../services/list-buttons.service';
import {DisplayService} from '../../../services/display.service';
import {NgxGalleryService} from '../../../services/ngx-gallery.service';
import {FileExamenList} from '../../../data/model/file';

@Component({
  selector: 'app-template-button',
  templateUrl: './template-button.component.html',
  styleUrls: ['./template-button.component.css', '../../app/app.component.css']
})

/**
 * Component used to display clinical test information on 'Evaluation Clinique' part
 */
export class TemplateButtonComponent implements OnInit {

  /**
   * Current expansion pane opened (if one is opened, otherwise step === -1)
   */
  step = -1;

  // string for url for displayService
  url: string;
  // table for rxjs
  galleryImages: NgxGalleryImage[ ];

  // boolean
  isComponentVisibleDsiplay: boolean;

  constructor(private listButtonsService: ListButtonsService, private displayService: DisplayService,
              private rxjsService: NgxGalleryService) {
    this.isComponentVisibleDsiplay = false;
  }

  ngOnInit() {
    // subscribe to diagnosticService
    this.rxjsService.cast.subscribe(images => this.galleryImages = images);
    this.displayService.castUrl.subscribe(url => this.url = url);
  }

  /**
   * Set expansion pane opened
   */
  setStep(idx: number): void {
    this.step = idx;
  }

  /**
   * Display selected clinical test content
   * @param event clinical test selected
   */
  onOpenExpansionPanel(event) {
    this.isComponentVisibleDsiplay = true;
    for (const test of this.listButtonsService.getCheckUps()) {
      if (event === test) {
        this.displayService.editResult(test.result);
        this.displayImages(test.media);
      }
    }
  }

  /**
   * Display files in interface from a list of files given their url
   * @param filesList list of files
   */
  displayImages(filesList: FileExamenList[]): void {
    this.rxjsService.editImages([]);
    for (const file of filesList) {
      if (file !== null) {
        this.displayService.editUrl(file.fileInString);
        this.galleryImages.push({
          small: this.url,
          medium: this.url,
          big: this.url,
        }, );
        this.rxjsService.editImages(this.galleryImages);
      }
    }
  }

}

