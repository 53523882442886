import {Component, OnInit} from '@angular/core';
import {TextService} from '../../../services/text.service';

@Component({
  selector: 'app-template-text',
  templateUrl: './template-text.component.html',
  styleUrls: ['./template-text.component.css']
})
export class TemplateTextComponent implements OnInit {
  // string for TextService
  text: string;

  constructor(private textService: TextService) {
  }

  ngOnInit() {
    // subscribe to textService
    this.textService.cast.subscribe(text => this.text = text);
  }

}
