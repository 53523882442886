import {Component, Input, OnInit} from '@angular/core';
import {NotesListService} from '../../../services/notes-list.service';
import {SelectedOptionService} from '../../../services/selected-option.service';
import {DiagnosticService} from '../../../services/diagnostic.service';
import {ListTreatmentService} from '../../../services/list-treatment.service';
import {ClinicalCaseService} from '../../../services/clinical-case.service';
import {ClinicalCase} from '../../../data/model/clinicalCase';

@Component({
  selector: 'app-notes-treatment',
  templateUrl: './notes-treatment.component.html',
  styleUrls: ['./notes-treatment.component.css']
})
export class NotesTreatmentComponent implements OnInit {
  @Input() clinicalCase: ClinicalCase;

  constructor(private notesService: NotesListService,
              private optionService: SelectedOptionService,
              private diagnosticService: DiagnosticService,
              private treatmentService: ListTreatmentService,
              private clinicalCaseService: ClinicalCaseService
  ) {
  }

  notes: string;
  SelectedOptions: string[];
  diagnostic = [];
  treatmentMedical: string[];
  treatmentOrtho: string[];
  treatmentFct: string[];
  treatmentSurgical: string[];
  treatmentOthers: string[];

  ngOnInit() {
    // Subscribe to clinical case service
    this.clinicalCase = this.clinicalCaseService.getClinicalCase();

    // subscribe to noteService
    this.notesService.cast.subscribe(notes => this.notes = notes);
    this.optionService.cast.subscribe(SelectedOptions => this.SelectedOptions = SelectedOptions);
    this.diagnosticService.cast.subscribe(diagnostic => this.diagnostic = diagnostic);
    this.treatmentService.castMedical.subscribe(treatmentMedical => this.treatmentMedical = treatmentMedical);
    this.treatmentService.castOrtho.subscribe(treatmentOrtho => this.treatmentOrtho = treatmentOrtho);
    this.treatmentService.castfct.subscribe(treatmentfct => this.treatmentFct = treatmentfct);
    this.treatmentService.castSurgical.subscribe(treatmentSurgical => this.treatmentSurgical = treatmentSurgical);
    this.treatmentService.castOthers.subscribe(treatmentOthers => this.treatmentOthers = treatmentOthers);

  }

}
