import {Component, Inject, Input, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatPaginator, MatSort, MatTableDataSource} from '@angular/material';
import {ClinicalCase} from '../../../data/model/clinicalCase';
import {CLINICALCASE} from '../../../data/mock/CLINICALCASE';
import {ClinicalCaseService} from '../../../services/clinical-case.service';

export interface DialogData {
  message: string;
}

@Component({
  selector: 'app-clinical-cases-data-table',
  templateUrl: './clinical-cases-data-table.component.html',
  styleUrls: ['./clinical-cases-data-table.component.css']
})
export class ClinicalCasesDataTableComponent implements OnInit {
  private message: any;

  constructor(public router: Router, private dialog: MatDialog, private clinicalCaseService: ClinicalCaseService) {
    // rempli le tableau avec le JSON récupéré du local storage permet de résoudre le problème du retour quand on repasse pas
    // par la connexion
    this.dataSource = new MatTableDataSource(this.b);
  }

  displayedColumns: string[] = ['name', 'fields', 'difficulty', 'idCreator', 'idCC', 'jouer'];
  dataSource: MatTableDataSource<ClinicalCase>;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  // variable du local storage tableaux des cas cliniques avec des infos générales
  b: any = JSON.parse(localStorage.getItem('cc'));
  // creation d'une variable pour récupere la colonnne sélectionnée
  selectedRowIndex: number;

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.paginator._intl.itemsPerPageLabel = 'Nombre de cas cliniques par page :';
    this.dataSource.sort = this.sort;
  }

  // methode de mat angular pour rechercher dans un tableau
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  launchClinicalTest() {
    this.clinicalCaseService.setClinicalCaseFromFile(JSON.stringify(CLINICALCASE[this.selectedRowIndex]));
    this.dialog.open(PopupRulesGameDialogComponent);
  }


}


// pop-up of play rules
@Component({
  selector: 'app-popup-rules-game-dialog',
  templateUrl: './PopupRulesGameDialog.html',
})

export class PopupRulesGameDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<PopupRulesGameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
  }

}

