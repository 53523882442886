import {Injectable} from '@angular/core';
import {ClinicalTest} from '../data/model/clinicalTest';

@Injectable({
  providedIn: 'root'
})
export class ListButtonsService {
  private checkUps: Array<ClinicalTest>;

  constructor() {
  }

  setCheckUp(newCheckUp) {
    this.checkUps = newCheckUp;
  }

  getCheckUps(): Array<ClinicalTest> {
    return this.checkUps;
  }

}
