import {Component, Input, OnInit} from '@angular/core';
import {AppComponent} from '../app/app.component';
import {MenuLeft} from '../../data/model/menuLeft';
import {ClinicalCaseService} from '../../services/clinical-case.service';

@Component({
  selector: 'app-add-game',
  templateUrl: './add-game.component.html',
  styleUrls: ['./add-game.component.css']
})

/**
 * This is the interface to create a new clinical case to play.
 */
export class AddGameComponent implements OnInit {

  /**
   * Selected category of left menu on '2- Evaluation Clinique' tab
   */
  currentCategory = '';

  /**
   * Categories of left menu on '2- Evaluation Clinique' tab
   *
   * Description of components:
   * - name: used in play-game component
   * - friendlyName: used in add-game component (the name has been adapted to stick to the fact that we want to create a case and not consult it)
   * - sousCategories: options available for a menu if there is any
   */
  @Input() categoriesMenuLeft: MenuLeft[] = [
    {
      name: 'Réaliser l\'patient-info',
      friendlyName: 'Interrogatoire',
      sousCategories: ['Interroger le patient', 'Informations sur le traumatisme', 'Informations sur la douleur']
    },
    {
      name: 'Examiner le patient',
      friendlyName: 'Examen du patient',
      sousCategories: ['Observations visuelles', 'Examen palpatoire']
    },
    {
      name: 'Réaliser des tests cliniques',
      friendlyName: 'Tests cliniques',
      sousCategories: ['Tête', 'Membre supérieur', 'Rachis', 'Membre Inférieur']
    },
    {
      name: 'Demander un examen d\'imagerie',
      friendlyName: 'Examens d\'imagerie',
      sousCategories: ['Demande de radiographie', 'Demande de radiographie dynamique', 'Demande de scanner',
        'Demande d\'arthroscanner', 'Demande d\'IRM', 'Demande de scintigraphie']
    },
    {
      name: 'Demander un examen biologique',
      friendlyName: 'Examen biologique',
      sousCategories: []},
  ];

  constructor(private appComponent: AppComponent, private clinicalCaseService: ClinicalCaseService) {
    this.appComponent.ShowButtonHome();
    this.appComponent.ShowButtonLogOut();
    this.appComponent.HideButtonHomefromTuto();
    this.appComponent.HideButtonLogOutFromTuto();
    this.appComponent.topTitle = ' Création d\'un cas clinique';
  }

  ngOnInit(): void {
  }

  /**
   * Change category selected on '2- Evaluation Clinique' tab
   * @param category category selected
   */
  onCategoryChanged(category: string): void {
    this.currentCategory = category;
  }

  /**
   * Save clinical case model to your hard disk.
   * This method will propose you to download a JSON file of the clinical case
   */
  saveClinicalCase(): void {
    this.clinicalCaseService.downloadClinicalCase();

    // Decomment the following line to download clinical case as zip with folder with files
    // this.downloadZipClinicalCase();
  }

}
