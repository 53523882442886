import {Component, OnInit} from '@angular/core';
import {ClinicalTest} from '../../../data/model/clinicalTest';
import {ClinicalCaseService} from '../../../services/clinical-case.service';

@Component({
  selector: 'app-tests-cliniques',
  templateUrl: './clinical-tests.component.html',
  styleUrls: ['./clinical-tests.component.css']
})

/**
 * This components is used to create clinical tests. It contains a list of clinical tests
 * The details of one clinical test can be found in ClinicalTestDetailComponent
 */
export class ClinicalTestsComponent implements OnInit {

  /**
   * List of clinical tests of the clinical case
   */
  clinicalTests: ClinicalTest[] = [];

  /**
   * Current expansion pane opened (if one is opened, otherwise step === -1)
   */
  step = 0;

  constructor(private clinicalCaseService: ClinicalCaseService) {
  }

  ngOnInit(): void {
    this.clinicalTests = this.clinicalCaseService.getClinicalCase().checkUpClinicalTestList;
  }

  /**
   * Delete a clinical test from the list
   * @param clinicalTest clinical test to delete
   */
  onDelete(clinicalTest: ClinicalTest): void {
    this.clinicalTests = this.clinicalTests.filter(obj => obj !== clinicalTest);
    this.saveData();
  }

  /**
   * Add a clinical test to the list
   */
  onAdd(): void {
    const test: ClinicalTest = new ClinicalTest(0, '', [], [], [], 10);
    this.clinicalTests.push(test);
    this.saveData();
    this.step = this.clinicalTests.length - 1;
  }

  /**
   * Go to next expansion pane
   */
  nextStep(): void {
    this.step++;
  }

  /**
   * Go to previous expansion pane
   */
  prevStep(): void {
    this.step--;
  }

  /**
   * Set expansion pane opened
   */
  setStep(idx: number): void {
    this.step = idx;
  }

  /**
   * Save clinical case model
   */
  saveData(): void {
    this.clinicalCaseService.saveClinicalTests(this.clinicalTests);
  }

}
