import {Component, OnInit} from '@angular/core';
import {Patient} from '../../../data/model/patient';
import {ClinicalCaseService} from '../../../services/clinical-case.service';

@Component({
  selector: 'app-patient-info',
  templateUrl: './patient-info.component.html',
  styleUrls: ['./patient-info.component.css']
})
/**
 * This component regroups all of patient's personal information
 */
export class PatientInfoComponent implements OnInit {

  /**
   * Patient general informations
   */
  patient: Patient = new Patient('', '', '', '', '', '');

  /**
   * Description of events leading to creation of clinical case
   */
  ListInfoTrauma: string[] = [];

  /**
   * Current expansion pane opened (if one is opened, otherwise step === -1)
   */
  step = -1;

  constructor(private clinicalCaseService: ClinicalCaseService) {
  }

  ngOnInit(): void {
    this.patient = this.clinicalCaseService.getClinicalCase().patient;
    this.ListInfoTrauma = this.clinicalCaseService.getClinicalCase().ListInfoTrauma;
  }

  /**
   * Set expansion pane opened
   */
  setStep(index: number): void {
    this.step = index;
  }

  /**
   * Go to next expansion pane
   */
  nextStep(): void {
    this.step++;
  }

  /**
   * Go to previous expansion pane
   */
  prevStep(): void {
    this.step--;
  }

  /**
   * Save clinical case model
   */
  saveData(): void {
    this.clinicalCaseService.savePersonalInfo(this.patient, this.ListInfoTrauma);
  }
}
