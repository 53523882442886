import {CareInterface} from '../../components/add-game/care/care.component';

/**
 * This class represents the treatments of the clinical case, also called 'care'.
 * Treatments have been separated in different categories, each one of them is represented by a list.
 */
export class Care {

  /**
   * Orthopedic treatments list.
   */
  orthopedicTreatmentList: Array<string>;

  /**
   * Medical treatments list.
   */
  medicalTreatmentList: Array<string>;

  /**
   * Functionnal treatments list.
   */
  functionalTreatmentList: Array<string>;

  /**
   * Surgical treatments list.
   */
  surgicalTreatmentList: Array<string>;

  /**
   * Other treatments list.
   * Store here any treatment that does not apply to other categories.
   */
  otherTreatmentList: Array<string>;

  constructor(orthopedicTreatmentList: Array<string>, medicalTreatmentList: Array<string>,
              functionalTreatmentList: Array<string>, surgicalTreatmentList: Array<string>, otherTreatmentList: Array<string>) {
    this.orthopedicTreatmentList = orthopedicTreatmentList;
    this.medicalTreatmentList = medicalTreatmentList;
    this.functionalTreatmentList = functionalTreatmentList;
    this.surgicalTreatmentList = surgicalTreatmentList;
    this.otherTreatmentList = otherTreatmentList;
  }

  /**
   * Add a new treatment to the associated treatment list
   * @param cat category of the treatment
   * @param desc new treatment description to add
   */
  addElement(cat: string, desc: string): void {
    if (cat === 'Orthopédique') {
      this.orthopedicTreatmentList.push(desc);
    } else if (cat === 'Médical') {
      this.medicalTreatmentList.push(desc);
    } else if (cat === 'Fonctionnel') {
      this.functionalTreatmentList.push(desc);
    } else if (cat === 'Chirurgical') {
      this.surgicalTreatmentList.push(desc);
    } else if (cat === 'Autre traitement') {
      this.otherTreatmentList.push(desc);
    }
  }

  /**
   * Remove a treatment from the stored treatments
   * @param treatment treatment to retrieve
   */
  removeElement(treatment: CareInterface): void {
    if (treatment.category === 'Orthopédique') {
      this.orthopedicTreatmentList = this.orthopedicTreatmentList.filter(obj => obj !== treatment.description);
    } else if (treatment.category === 'Médical') {
      this.medicalTreatmentList = this.medicalTreatmentList.filter(obj => obj !== treatment.description);
    } else if (treatment.category === 'Fonctionnel') {
      this.functionalTreatmentList = this.functionalTreatmentList.filter(obj => obj !== treatment.description);
    } else if (treatment.category === 'Chirurgical') {
      this.surgicalTreatmentList = this.surgicalTreatmentList.filter(obj => obj !== treatment.description);
    } else if (treatment.category === 'Autre traitement') {
      this.otherTreatmentList = this.otherTreatmentList.filter(obj => obj !== treatment.description);
    }
  }
}
