import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {Diagnostic} from '../../../data/model/diagnostic';
import {MatTable} from '@angular/material/table';
import {ClinicalCaseService} from '../../../services/clinical-case.service';

/**
 * Representation of one diagnostic of clinical case
 * If the diagnostic is choosen by expert, the boolean isSelectedByExpert is set to true
 */
export interface DiagnosticInterface {
  diagnostic: string;
  isSelectedByExpert: boolean;
}

@Component({
  selector: 'app-diagnostic',
  templateUrl: './diagnostic.component.html',
  styleUrls: ['./diagnostic.component.css']
})
/**
 * This component regroups the list of the diagnostics that will be displayed in the game and also what are
 * the right answers.
 */
export class DiagnosticComponent implements OnInit {

  /**
   * Input field of new diagnostic
   */
  @Input() diagnostic = '';

  /**
   * For interface
   */
  displayedColumns = ['diagnostic', 'avis', 'supp'];

  /**
   * Data used to fill diagnostic table.
   * It represents what the user sees on the interface.
   * The link with clinical case model should be based on the content of this variable afterwards.
   */
  @Input() dataSource: DiagnosticInterface[] = [];

  /**
   * a cache when you select and unselect "medical reorientation" button
   */
  diagnosticBoxChecked: Diagnostic = new Diagnostic([]);

  /**
   * the list of diagnostics which the user will be able to chose during the game
   */
  diagnosticGame: Diagnostic = new Diagnostic([]);

  /**
   * the list of diagnostics among diagnosticGame which will be a right answer (selected by experts)
   */
  diagnosticSolution: Diagnostic = new Diagnostic([]);

  /**
   * Store whether clinical case should be reoriented or not
   */
  medicalReorientation = false;

  @ViewChild(MatTable, {static: true}) table!: MatTable<any>;

  /**
   * Small field filled when a message about new diagnostic input needs to be told (error message)
   */
  errorMsgAddRow = '';

  /**
   * Field to describe the approach taken by experts to solve the clinical case
   */
  logCorrection = '';

  constructor(private clinicalCaseService: ClinicalCaseService) {
  }

  ngOnInit(): void {
    this.diagnosticGame = this.clinicalCaseService.getClinicalCase().diagnosticGame;
    this.diagnosticSolution = this.clinicalCaseService.getClinicalCase().diagnosticSolution;
    this.diagnosticBoxChecked = this.clinicalCaseService.getClinicalCase().diagnosticSolution;
    this.logCorrection = this.clinicalCaseService.getClinicalCase().logCorrection;

    // implement dataSource
    for (const diag of this.diagnosticGame.diagnosticList) {
      let isExpert = false;
      if (this.diagnosticSolution.diagnosticList.find(elem => elem === diag) !== undefined) {
        isExpert = true;
      }
      this.dataSource.push({diagnostic: diag, isSelectedByExpert: isExpert});
    }
  }

  /**
   * Add a new diagnostic
   */
  addRow(): void {
    if (this.diagnostic.length > 0) {
      this.dataSource.push({diagnostic: this.diagnostic, isSelectedByExpert: false});
      this.table.renderRows();
      this.diagnostic = '';
      this.errorMsgAddRow = '';
    } else {
      this.errorMsgAddRow = 'Le champ description doit être rempli.';
    }
    this.saveDataSourceIntoModel();
  }

  /**
   * Remove a diagnostic
   * @param diag diagnostic to remove
   */
  removeRow(diag: DiagnosticInterface): void {
    this.dataSource = this.dataSource.filter(obj => obj !== diag);
    this.saveDataSourceIntoModel();
    this.table.renderRows();
  }

  /**
   * Handles changes of diagnostic selected by expert
   * @param checked whether diagnostic is selected by experts or not
   * @param index index of diagnostic selected
   */
  modifyDiagnosticSolution(checked: boolean, index: number): void {
    if (!checked) {
      this.dataSource[index].isSelectedByExpert = true;
    } else {
      this.dataSource[index].isSelectedByExpert = false;
    }
    this.saveDataSourceIntoModel();
  }

  /**
   * Handle changes of reorientation on whether clinical case should be reoriented or not
   * @param checked whether reorientation is selected or not
   */
  onReorientationSelected(checked: boolean): void {
    if (checked) {
      this.medicalReorientation = false;
    } else {
      this.medicalReorientation = true;
      this.diagnosticBoxChecked = this.diagnosticSolution;
    }
    this.saveDataSourceIntoModel();
  }

  /**
   * Change description of a diagnostic already created
   */
  diagnosticDescriptionChanged(): void {
    this.saveDataSourceIntoModel();
  }

  /**
   * Interface between dataSource and clinical case model
   */
  saveDataSourceIntoModel(): void {
    this.diagnosticSolution = new Diagnostic([]);
    this.diagnosticGame = new Diagnostic([]);

    for (const diag of this.dataSource) {
      if (!this.medicalReorientation && diag.isSelectedByExpert) {
        this.diagnosticSolution.addDiagnostic(diag.diagnostic);
      }
      this.diagnosticGame.diagnosticList.push(diag.diagnostic);
    }
    if (this.medicalReorientation) {
      this.diagnosticSolution = new Diagnostic(['Réorientation immédiate']);
    }
    this.saveData();
  }

  /**
   * Save clinical case model
   */
  saveData(): void {
    this.clinicalCaseService.saveDiagnostics(this.diagnosticGame, this.diagnosticSolution, this.logCorrection);
  }
}
