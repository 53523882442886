/**
 * This class represents general information about the patient
 */
export class Patient {

  /**
   * Patient general information (name, age, sex, ...)
   */
  InfoPatientList: string;

  /**
   * Patient activities information
   */
  ActivitiesList: string;

  /**
   * Patient living place information
   */
  LivingPlaceList: string;

  /**
   * Patient social situation information
   */
  SocialSituationList: string;

  /**
   * Patient medical history information
   */
  MedicalHistoryList: string;

  /**
   * Patient pain information
   */
  PainList: string;

  constructor(InfoPatientList: string, ActivitiesList: string, LivingPlaceList: string, SocialSituationList: string, MedicalHistoryList: string, PainList: string) {
    this.InfoPatientList = InfoPatientList;
    this.ActivitiesList = ActivitiesList;
    this.LivingPlaceList = LivingPlaceList;
    this.SocialSituationList = SocialSituationList;
    this.MedicalHistoryList = MedicalHistoryList;
    this.PainList = PainList;
  }
}
