import {FileExamenList, Helper} from './file';

/**
 * This class represents a clinical test.
 */
export class ClinicalTest {

  /**
   * Clinical case id
   */
  idCC: number;

  /**
   * Type of clinical test ('Epaule', 'Cheville', 'Coude', ...)
   */
  checkUpType: string;

  /**
   * List of files of the check up
   */
  media: FileExamenList[];

  /**
   * Result, description of the check up
   */
  result: string[];

  /**
   * @ignore
   */
  time: number;

  /**
   * Title of the check up
   * (this field might be unused, can be deleted after further checks)
   */
  title: string[];

  constructor(idCC: number, checkUpType: string, media: FileExamenList[], result: string[], title: string[], time: number) {
    this.idCC = idCC;
    this.checkUpType = checkUpType;
    this.media = media;
    this.result = result;
    this.title = title;
    this.time = time;
  }

  /**
   * Add a new file to file list, using storage in JSON of filename to get in database or zip folder
   * @param file file object
   * @param category file category. Used to rename file for storage to be uniquely identified.
   * Categories are 'check_up_biology', 'check_up_image', 'check_up_observation', 'clincial_test_TESTCATEGORY'
   */
  addFile(file: File) {
    this.media.push({
      // filename is temporary, before downloading or televersing anything, call ClinicalTest::generateFileNames to create associated file name
      fileInString: this.media.length.toString(),

      fileObject: file
    });
  }

  /**
   * Add a new file to file list, using storage in JSON of converted file in string.
   * @param file file object
   * @param fileInString file object converted in string
   */
  addFileBlobInJSON(file: File, fileInString: string) {
    this.media.push({
      fileInString: fileInString,
      fileObject: file
    });
  }

  /**
   * Generate file name to rename file for storage to be uniquely identified.
   * @param testId id of the test
   */
  generateFileNames(testId: string) {
    for (const elem of this.media) {
      elem.fileInString = elem.fileInString + '_clinical_test_' + this.checkUpType + '_' + testId + '.' + Helper.findExtension(elem.fileObject.name);
    }
  }
}
